<template>
  <div class="personal-info">
    <h2>Личная информация</h2>
    <div class="personal-info__form">
      <div class="personal-info__form-block">
        <span class="label title">Телефон</span>
        <span class="label">{{ labelPhone }}</span>
      </div>
      <div class="personal-info__form-block">
        <span class="label title">ФИО</span>
        <span
          v-if="mode === 'read'"
          class="label"
        >
          {{ form.fio && form.fio.trim().length ? form.fio : 'Отсутствует' }}
        </span>
        <div
          v-else
          class="field"
        >
          <input
            v-model="form.fio"
            type="text"
            :class="{ 'error' : validationResult && validationResult.fieldErrors.fio }"
          >
          <span
            v-if="validationResult && validationResult.fieldErrors.fio"
            class="personal-info__form-error"
          >
            {{ validationResult.fieldErrors.fio[0] }}
          </span>
        </div>
      </div>
      <div class="personal-info__form-block">
        <span class="label title">Дата рождения</span>
        <span
          v-if="mode === 'read'"
          class="label"
        >
          {{ birthDate ? birthDate : 'Отсутствует' }}
        </span>
        <div
          v-else
          class="field"
        >
          <InputDate
            v-model="form.birthDate"
            width="347px"
            class="desktop-date"
            :class="{ 'error' : validationResult && validationResult.fieldErrors.birthDate }"
          />
          <input
            v-model="form.birthDate"
            type="date"
            class="mobile-date"
          >
          <span
            v-if="validationResult && validationResult.fieldErrors.birthDate"
            class="personal-info__form-error"
          >
            {{ validationResult.fieldErrors.birthDate[0] }}
          </span>
        </div>
      </div>
      <div class="personal-info__form-block">
        <span class="label title">Почта</span>
        <span
          v-if="mode === 'read'"
          class="label"
        >
          {{ form.email ? form.email : 'Отсутствует' }}
        </span>
        <div
          v-else
          class="field"
        >
          <input
            v-model="form.email"
            type="text"
            :class="{ 'error' : validationResult && validationResult.fieldErrors.email }"
          >
          <span
            v-if="validationResult && validationResult.fieldErrors.email"
            class="personal-info__form-error"
          >
            {{ validationResult.fieldErrors.email[0] }}
          </span>
        </div>
      </div>
    </div>
    <button v-if="mode === 'read'" @click="mode = 'edit'">Редактировать</button>
    <div v-else class="personal-info__btn-group">
      <button @click="submitInfo">Сохранить</button>
      <button class="secondary" @click="cancelEdit">Отмена</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InputDate from '@/components/profile/InputDate.vue'
export default {
  name: 'PersonalInfo',
  components: {
    InputDate
  },
  data: () => ({
    mode: 'read',
    form: {
      phone: null,
      fio: null,
      birthDate: null,
      email: null
    },
    validationResult: null
  }),
  computed: {
    labelPhone () {
      if (this.form.phone) {
        return `${this.form.phone.slice(0, 2)} ${this.form.phone.slice(2, 5)} ${this.form.phone.slice(5, 8)} ${this.form.phone.slice(8, 10)} ${this.form.phone.slice(10, 12)}`
      }
      return ''
    },
    userId () {
      return localStorage.getItem('userId')
    },
    birthDate () {
      if (this.form.birthDate) {
        const date = new Date(this.form.birthDate)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`
      }
      return null
    }
  },
  methods: {
    ...mapActions('profile', ['getPersonalInfo', 'updatePersonalInfo']),
    submitInfo () {
      const data = {
        ...this.form,
        id: this.userId,
        validationResult: {
          hasNestedFormErrors: false
        }
      }
      this.updatePersonalInfo(data)
        .then(({ data }) => {
          this.validationResult = data.validationResult
          if (!Object.keys(this.validationResult.fieldErrors).length) {
            this.getPersonalInfo()
              .then(({ data }) => {
                this.form = JSON.parse(JSON.stringify(data))
                this.validationResult = null
                this.mode = 'read'
              })
              .catch(error => error)
          }
        })
        .catch(error => error)
    },
    cancelEdit () {
      this.getPersonalInfo()
        .then(({ data }) => {
          this.form = JSON.parse(JSON.stringify(data))
          this.validationResult = null
          this.mode = 'read'
        })
        .catch(error => error)
    }
  },
  created () {
    this.getPersonalInfo()
      .then(({ data }) => {
        this.form = JSON.parse(JSON.stringify(data))
      })
      .catch(error => error)
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";

.personal-info {
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 40px;
    @media (max-width: 900px) {
      text-align: center;
    }
    @media (max-width: 600px) {
      font-size: 24px;
      margin: 0 0 24px;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    @media (max-width: 600px) {
      max-width: 400px;
      width: 100%;
    }
    &-block {
      display: flex;
      align-items: center;
      gap: 26px;
      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
      .label {
        font-size: 16px;
        font-weight: 400;
        width: 350px;
        @media (max-width: 900px) {
          width: auto;
        }
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
      .title {
        width: 212px;
        @media (max-width: 600px) {
          color: #979797;
        }
      }
    }
    input {
      border: 1px solid $tertiary-color;
      outline: none;
      max-height: 40px;
      display: flex;
      align-items: center;
      padding: 9px 12px;
      background: $white;
      font-size: 16px;
      border-radius: 8px;
      width: 321px !important;
      box-sizing: content-box;
      @media (max-width: 600px) {
        font-size: 14px;
      }
      &.error {
        color: $error;
        border: 1px solid $error;
      }
    }
    &-error {
      font-size: 12px;
      color: $error;
      margin-top: 4px;
    }
  }
  button {
    display: flex;
    width: 333px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #FFEFD9;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
    border: none;
    outline: none;
    &:hover {
      background: #FFD79E;
    }
    @media (max-width: 600px) {
      font-size: 16px;
      max-width: 400px;
      width: calc(100vw - 30px);
    }
  }
  &__btn-group {
    display: flex;
    gap: 24px;
    @media (max-width: 600px) {
      flex-direction: column;
      gap: 12px;
    }
    button {
      width: 273px;
      @media (max-width: 600px) {
        max-width: 400px;
        width: calc(100vw - 30px);
      }
    }
    .secondary {
      background: $white;
      border: 1px solid #FFD79E;
      &:hover {
        background: rgba(255, 239, 217, 0.2);
        border: 1px solid #FFD79E;
      }
    }
  }
}
</style>
