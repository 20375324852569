<template>
  <div class="card-menu">
    <div class="card-menu__image">
      <img v-if="dish.imagePath" :src="dish.imagePath" alt="dish" />
      <div v-else class="card-menu__image-mock"><img :src="require('../assets/icons/characteristic.svg')" alt=""></div>
    </div>
    <div class="card-menu__content">
      <div class="card-menu__group">
        <div class="card-menu__caption">{{ dish.type }}</div>
        <div class="card-menu__title">
          <span class="card-menu__title-text">{{ dish.name }}</span>
          <span
            ref="tooltip"
            class="card-menu__title-tooltip"
            :style="styleTooltip"
          >
            {{ dish.name }}
          </span>
        </div>
      </div>
      <div class="card-menu__weight">{{ dish.weight }} г</div>
      <div class="card-menu__price">{{ dish.cost }} ₽</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardMenu',
  props: {
    dish: {
      type: Object,
      required: true
    }
  },
  computed: {
    styleTooltip () {
      return { bottom: `-${this.$refs.tooltip?.clientHeight}`, right: `-${this.$refs.tooltip?.clientWidth}` }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";
.card-menu {
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  border-radius: 10px;
  padding: 10px;
  gap: 5px;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }
  &__image {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    width: 315px;
    height: 315px;
    display: flex;
    img {
      object-fit: cover;
      max-width: 100%;
    }
    @media (max-width: 320px) {
      width: 300px;
    }
    div {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
      img {
        height: 120px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__caption, &__weight {
    font-size: $secondary-font-size;
    color: $secondary-color;
  }
  &__title {
    font-size: $primary-font-size;
  }
  &__price {
    font-size: $primary-font-size;
    font-weight: bold;
  }
  &__title {
    position: relative;
    &-text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      &:hover + .card-menu__title-tooltip {
        opacity: 1;
      }
    }
    &-tooltip {
      position: absolute;
      border-radius: 5px;
      top: 80%;
      left: 50%;
      opacity: 0;
      z-index: 1;
      width: fit-content;
      max-width: 300px;
      min-width: 200px;
      background-color: $white;
      border: 1px solid $secondary-color;
      padding: 10px;
      font-size: $primary-font-size;
      transition: .2s all ease-in-out;
      @media (max-width: 1580px) {
        min-width: 150px;
      }
      @media (max-width: 320px) {
        min-width: 100px;
      }
    }
  }
}
</style>
