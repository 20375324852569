<template>
  <div class="home">
    <div>
      <SectionBanner />
      <SectionDelivery />
    </div>
    <SectionMenu :display-width="displayWidth" />
    <SectionSeparator />
    <SectionPromotions :display-width="displayWidth" />
    <SectionNews />
    <SectionCafe />
    <SectionFranchise
      @open-policy="modeModal = 'policy'"
    />
    <Modal v-if="modeModal !== 'none'" @close="modeModal = 'none'">
      <Policy v-if="modeModal === 'policy'" mode="sign-up" />
    </Modal>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

import SectionBanner from '../components/home/SectionBanner'
import SectionDelivery from '../components/home/SectionDelivery'
import SectionMenu from '../components/home/SectionMenu'
import SectionPromotions from '../components/home/SectionPromotions'
import SectionNews from '../components/home/SectionNews'
import SectionCafe from '../components/home/SectionCafe'
import SectionSeparator from '../components/home/SectionSeparator'
import SectionFranchise from '../components/home/SectionFranchise'
import Modal from '../components/Modal'
import Policy from '../components/popups/Policy'
export default {
  name: 'Home',
  components: {
    Policy,
    SectionFranchise,
    SectionSeparator,
    SectionCafe,
    SectionNews,
    SectionPromotions,
    SectionMenu,
    SectionDelivery,
    SectionBanner,
    Modal
  },
  data: () => ({ modeModal: 'none' }),
  computed: {
    displayWidth () {
      return document.body.clientWidth
    }
  },
  watch: {
    modeModal (value) {
      if (value !== 'none') document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'auto'
    }
  }
}
</script>
<style lang="scss">
.home {
  display: flex;
  flex-direction: column;
  gap: 45px;
}
</style>
