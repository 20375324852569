import api from '@/boot/api'
import VueCookies from 'vue-cookies'

const state = {
  cardInfo: null
}

const getters = {
  cardInfo: state => state.cardInfo
}

const mutations = {
  GET_CARD_INFO (state, payload) {
    state.cardInfo = payload
  }
}

const actions = {
  getCurrentCard ({ commit }) {
    return new Promise((resolve, reject) => {
      api.get('loyalties/cards/current', {
        headers: {
          Authorization: `Bearer ${VueCookies.get('token')}`
        }
      })
        .then(({ data }) => {
          commit('GET_CARD_INFO', data.cardInfo)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async getPersonalInfo () {
    const userId = localStorage.getItem('userId')
    api.defaults.headers.common.Authorization = `Bearer ${VueCookies.get('token')}`
    return await api.post(`persons/upsert/init/${userId}`)
  },
  async updatePersonalInfo (_, data) {
    api.defaults.headers.common.Authorization = `Bearer ${VueCookies.get('token')}`
    console.log(data)
    return await api.post('persons/upsert/submit', data)
  },
  async getFormVirtualCard () {
    const userId = localStorage.getItem('userId')
    api.defaults.headers.common.Authorization = `Bearer ${VueCookies.get('token')}`
    return await api.post(`loyalties/cards/actions/new/init/${userId}`)
  },
  async submitVirtualCard (_, data) {
    api.defaults.headers.common.Authorization = `Bearer ${VueCookies.get('token')}`
    return await api.post('loyalties/cards/actions/new/submit', data)
  },
  async getFormCurrentCard () {
    const userId = localStorage.getItem('userId')
    api.defaults.headers.common.Authorization = `Bearer ${VueCookies.get('token')}`
    return await api.post(`loyalties/cards/actions/assign/init/${userId}`)
  },
  async submitCurrentCard (_, data) {
    api.defaults.headers.common.Authorization = `Bearer ${VueCookies.get('token')}`
    return await api.post('loyalties/cards/actions/assign/submit', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
