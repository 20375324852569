<template>
  <div class="map">
    <div id="yandex-map" style="width: 100%; height: 100%;"></div>
  </div>
  <div class="addresses">
    <div
      class="addresses-item"
      v-for="(item, index) in addresses"
      :key="index"
      @click="rerenderMap(item)"
    >
      <img class="addresses-icon" src="../assets/icons/point-map.svg" alt="point-map"/>
      <div class="addresses-title">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Map',
  props: {
    coords: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    localCoords: []
  }),
  computed: {
    ...mapGetters('landing', ['addresses'])
  },
  created () {
    this.localCoords = this.coords
    const scriptYandexMap = document.createElement('script')
    scriptYandexMap.setAttribute('src', 'https://api-maps.yandex.ru/2.1/?lang=ru_RU')
    document.head.appendChild(scriptYandexMap)
    scriptYandexMap.addEventListener('load', this.initializeYandexMap)
  },
  methods: {
    rerenderMap (address) {
      const myMap = document.getElementById('yandex-map')
      myMap.innerHTML = ''
      this.localCoords = [address.lat, address.long]
      this.initializeYandexMap()
    },
    initializeYandexMap () {
      // eslint-disable-next-line no-undef
      ymaps.ready(() => {
        // eslint-disable-next-line no-undef
        this.map = new ymaps.Map('yandex-map', {
          center: this.localCoords,
          zoom: 17,
          controls: ['zoomControl'],
          searchControlProvider: 'yandex#search'
        })
        this.map.behaviors.disable('scrollZoom')
        this.setMarkers()
        // this.coordinates.then(() => this.setMarkers());
      })
    },
    setMarkers (coords) {
      // eslint-disable-next-line no-undef
      const placemark = new ymaps.Placemark(this.localCoords, {}, {
        preset: 'islands#circleDotIcon',
        iconColor: '#ff0000'
      })
      this.map.geoObjects.add(placemark)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";

.map {
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.addresses {
  position: absolute;
  width: 345px;
  height: 80%;
  right: 30px;
  top: 70px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  @media (max-width: 1023px) {
    display: none;
  }

  &-item {
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;

    &:hover {
      background-color: $primary-color;
    }

  }

  &-title {
    font-size: $primary-font-size;
  }
}
</style>
