<template>
  <VueDatePicker
    :model-value="date"
    locale="ru"
    auto-apply
    :markers="markers"
    month-name-format="long"
    utc
    position="left"
    :format="format"
    @update:model-value="updateDate"
  />
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'UIDate',
  components: {
    VueDatePicker
  },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    width: {
      type: String
    }
  },
  data: () => ({
    date: '',
    markers: [
      {
        date: new Date(),
        type: 'dot',
        color: '#FFA930'
      }
    ]
  }),
  watch: {
    modelValue: {
      handler (value) {
        if (value !== this.date) {
          this.date = value
        }
      },
      immediate: true
    }
  },
  methods: {
    format (date) {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`
    },
    updateDate (date) {
      this.date = date
      this.$emit('update:modelValue', date)
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/vars";

.dp__menu_index {
  position: fixed;
}

.dp__today {
  border: none;
  color: #FFA930;
}

.dp__active_date {
  background: #FFA930;
}

.dp__input {
  border: 1px solid $tertiary-color;
  border-radius: 8px;
  width: v-bind(width);
  padding: 8px 12px 8px 30px;
}
.error {
  .dp__input {
    color: $error;
    border: 1px solid $error;
  }
}

.dp__overlay_cell_active {
  background: #FFA930;
}
</style>
