<template>
  <AuthPopup @close-modal="$emit('closeModal')">
    <div class="auth-modal__form">
      <div class="auth-modal__title">Войти</div>
      <div class="auth-modal__caption">Новый пользователь? <span class="link" @click="$emit('openRegistration')">Создать учетную запись</span></div>
      <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.phone }">
        <span class="auth-modal__label-name">Телефон</span>
        <input v-model="form.phone" @input="changePhone" type="tel" class="auth-modal__label-input">
        <img v-if="validationResult && validationResult.fieldErrors.phone" class="auth-modal__label-icon" src="../../../assets/icons/auth-icon-error.svg" alt="icon-error" />
        <span v-if="validationResult && validationResult.fieldErrors.phone" class="auth-modal__label-error">{{ validationResult.fieldErrors.phone[0] }}</span>
      </div>
      <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.password }">
        <span class="auth-modal__label-name">Пароль</span>
        <input v-model="form.password" :type="isShowPassword ? 'text' : 'password'" class="auth-modal__label-input">
        <img
          v-if="form.password.length"
          class="auth-modal__label-icon auth-modal__label-icon--clickable"
          :src="require(`../../../assets/icons/auth-icon-eye${validationResult && validationResult.fieldErrors.password ? '-error' : ''}.svg`)"
          alt="icon-error"
          @click="isShowPassword = !isShowPassword"
        />
        <span class="auth-modal__label-link" @click="$emit('openRecoveryPassword')">Забыли пароль?</span>
        <span v-if="validationResult && validationResult.fieldErrors.password" class="auth-modal__label-error">{{ validationResult.fieldErrors.password[0] }}</span>
      </div>
      <button class="auth-modal__button" @click="loginUser">Войти</button>
    </div>
  </AuthPopup>
</template>

<script>
import AuthPopup from '@/components/auth/AuthPopup.vue'
import { mapActions } from 'vuex'

export default {
  name: 'LoginForm',
  components: {
    AuthPopup
  },
  data: () => ({
    form: {
      phone: '',
      password: ''
    },
    validationResult: null,
    isShowPassword: false
  }),
  methods: {
    ...mapActions('auth', ['login', 'checkAuth']),
    changePhone () {
      const x = this.form.phone.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
      if (x.input === '') {
        this.form.phone = ''
        return
      }
      if (!x[2] && x[1] !== '') {
        this.form.phone = x[1] === '8' || x[1] === '7' ? '+7' : '+7' + x[1]
      } else {
        this.form.phone = '+' + (x[2] ? x[1] + ' ' + x[2] : x[1]) + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '') + (x[5] ? ' ' + x[5] : '')
      }
    },
    loginUser () {
      const data = {
        validationResult: {
          hasNestedFormErrors: true
        },
        phone: this.form.phone.replace(/\s/g, ''),
        password: this.form.password
      }
      this.login(data)
        .then(({ data }) => {
          this.$cookies.set('token', data.token)
          this.$emit('successLogin')
          this.checkAuth()
            .then(({ data }) => {
              localStorage.setItem('userId', data.id)
            })
          // if (data.validationResult.hasErrors) {
          //   this.validationResult = data.validationResult
          // } else {
          //   document.cookie = `token=${data.token}`
          //   this.$emit('successLogin')
          // }
        })
        .catch((error) => error)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
