import api from '@/boot/api'
import VueCookies from 'vue-cookies'

const actions = {
  async registrationSubmit (_, data) {
    return await api.post('users/registration/submit', data)
  },
  async registrationConfirm (_, data) {
    return await api.post('users/registration/confirmation/submit', data)
  },
  async resendCode () {
    return await api.post(`users/${localStorage.getItem('userId')}/verification`)
  },
  async recoveryPasswordSubmit (_, data) {
    return await api.post('users/passwords/recovery/submit', data)
  },
  async recoveryPasswordConfirm ({ commit }, data) {
    return await api.post('users/passwords/recovery/confirmation/submit', data)
  },
  async login (_, data) {
    return await api.post('user-sessions', data)
  },
  async logout () {
    localStorage.removeItem('userId')
    return await api.delete('user-sessions', {
      headers: {
        Authorization: `Bearer ${VueCookies.get('token')}`
      }
    })
  },
  async checkAuth () {
    return await api.get('user-sessions/current', {
      headers: {
        Authorization: `Bearer ${VueCookies.get('token')}`
      }
    })
  }
}

export default {
  namespaced: true,
  actions
}
