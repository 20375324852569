<template>
  <div id="franchise" class="franchise">
    <div class="franchise__content">
      <img src="../../assets/images/franchise-logo.svg" alt="logo" style="max-height: 300px;"/>
      <div class="franchise__form">
        <h3>Присоединяйся к франшизе!</h3>
        <div class="franchise__label">
          <span class="franchise__caption">Название организации / ИП</span>
          <input
            v-model="form.fio"
            placeholder="Название"
          />
          <span v-if="errors.fio" class="franchise__error">
            <img src="../../assets/icons/icon-error.svg" alt="error" />
            <span>{{ errors.fio[0] }}</span>
          </span>
        </div>
        <div class="franchise__label">
          <span class="franchise__caption">Телефон</span>
          <input
            v-model="form.phone"
            type="tel"
            placeholder="+7"
            @input="changePhone"
          />
          <span v-if="errors.phone" class="franchise__error">
            <img src="../../assets/icons/icon-error.svg" alt="error" />
            <span>{{ errors.phone[0] }}</span>
          </span>
        </div>
        <div class="franchise__label">
          <span class="franchise__caption">E-mail</span>
          <input
            v-model="form.email"
            placeholder="E-mail"
          />
          <span v-if="errors.email" class="franchise__error">
            <img src="../../assets/icons/icon-error.svg" alt="error" />
            <span>{{ errors.email[0] }}</span>
          </span>
        </div>
        <div class="franchise__label">
          <span class="franchise__caption">Город</span>
          <input
            v-model="form.city"
            placeholder="Город"
          />
          <span v-if="errors.city" class="franchise__error">
            <img src="../../assets/icons/icon-error.svg" alt="error" />
            <span>{{ errors.city[0] }}</span>
          </span>
        </div>
        <div class="franchise__label">
          <div class="franchise__checkbox">
            <span
              class="franchise__checkbox-item"
              :class="{ 'franchise__checkbox-item--checked' : form.isLandlord }"
              @click="form.isLandlord = !form.isLandlord"
            ></span>
            <span class="franchise__checkbox-caption">Я - арендодатель</span>
          </div>
          <div v-if="errors.isLandlord" class="franchise__error">
            <img src="../../assets/icons/icon-error.svg" alt="error" />
            <span>{{ errors.isLandlord[0] }}</span>
          </div>
        </div>
        <div class="franchise__label">
          <div class="franchise__checkbox">
            <span
              class="franchise__checkbox-item"
              :class="{ 'franchise__checkbox-item--checked' : form.readPrivacyPolicy }"
              @click="form.readPrivacyPolicy = !form.readPrivacyPolicy"
            ></span>
            <span class="franchise__checkbox-caption">Я даю согласие на <a @click.stop="$emit('open-policy')">обработку персональных данных</a></span>
          </div>
          <div v-if="errors.readPrivacyPolicy" class="franchise__error">
            <img src="../../assets/icons/icon-error.svg" alt="error" />
            <span>{{ errors.readPrivacyPolicy[0] }}</span>
          </div>
        </div>
        <div class="franchise__label">
          <div class="franchise__checkbox">
            <span
              class="franchise__checkbox-item"
              :class="{ 'franchise__checkbox-item--checked' : form.wantsToGetInfoOradvertisingMessages }"
              @click="form.wantsToGetInfoOradvertisingMessages = !form.wantsToGetInfoOradvertisingMessages"
            ></span>
            <span class="franchise__checkbox-caption">Я согласен на получение рекламы и информационных сообщений</span>
          </div>
          <div v-if="errors.wantsToGetInfoOradvertisingMessages" class="franchise__error">
            <img src="../../assets/icons/icon-error.svg" alt="error" />
            <span>{{ errors.wantsToGetInfoOradvertisingMessages[0] }}</span>
          </div>
        </div>
        <button @click="submit"><span>Отправить заявку</span></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { toast } from 'vue3-toastify'

export default {
  name: 'SectionFranchise',
  data: () => ({
    form: {},
    errors: {}
  }),
  watch: {
    'form.phone' (oldValue, value) {
      if (value && !value.includes('+')) this.form.phone = '+7' + value
      if (value && value.length > 11) this.form.phone = oldValue
    }
  },
  computed: {
    ...mapGetters('landing', ['resultFranchise'])
  },
  methods: {
    ...mapActions('landing', ['sendFranchise']),
    setInitForm () {
      this.form = {
        validationResult: {
          hasNestedFormErrors: false
        },
        fio: '',
        phone: '',
        email: '',
        city: '',
        isLandlord: false,
        readPrivacyPolicy: false,
        wantsToGetInfoOradvertisingMessages: false
      }
    },
    changePhone () {
      const x = this.form.phone.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
      if (x.input === '') {
        this.form.phone = ''
        return
      }
      if (!x[2] && x[1] !== '') {
        this.form.phone = x[1] === '8' || x[1] === '7' ? '+7' : '+7' + x[1]
      } else {
        this.form.phone = '+' + (x[2] ? x[1] + ' ' + x[2] : x[1]) + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '') + (x[5] ? ' ' + x[5] : '')
      }
    },
    submit () {
      const data = {
        ...this.form,
        phone: this.form.phone.replace(/\s/g, '')
      }
      this.sendFranchise(data)
        .then(() => {
          if (this.resultFranchise.hasErrors) {
            this.errors = this.resultFranchise.fieldErrors
          } else {
            this.errors = {}
            this.setInitForm()
            toast.success('Ваша заявка успешно зарегистрирована', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch((error) => error)
    }
  },
  beforeMount () {
    this.setInitForm()
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";
.franchise {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 50px;
  background-image: url("../../assets/images/franchise-bg.svg");
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  @media (max-width: 800px) {
    min-height: auto;
    padding: 70px 30px;
  }
  @media (max-width: 600px) {
    padding: 70px 10px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1620px;
    width: 100%;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    > img {
      @media (max-width: 1440px) {
        width: 450px;
      }
      @media (max-width: 1100px) {
        width: 300px;
      }
      @media (max-width: 600px) {
        width: 160px;
      }
    }
  }
  &__form {
    max-width: 460px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    h3 {
      font-weight: 700;
      font-size: 28px;
      line-height: 32px;
      text-transform: uppercase;
      color: white;
      margin: 0;
    }
    input {
      border: none;
      outline: none;
      padding: 8px;
      max-width: 460px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 8px;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: black;
      box-sizing: border-box;
      &::placeholder {
        color: #CCCCCC;
      }
    }
    button {
      border: none;
      outline: none;
      max-width: 460px;
      width: 100%;
      height: 67px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFEFD9;
      border-radius: 15px;
      cursor: pointer;
      span {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-transform: uppercase;
      }
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
  }
  &__caption {
    font-size: 28px;
    line-height: 48px;
    color: white;
    text-transform: uppercase;
  }
  &__error {
    margin-top: 5px;
    display: flex;
    gap: 10px;
    align-items: center;
    > span {
      color: $accent-color;
      font-size: 18px;
      line-height: 23px;
    }
  }
  &__checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    &-item {
      min-width: 24px;
      height: 24px;
      background-color: #FFFFFF;
      border-radius: 5px;
      cursor: pointer;
      &--checked {
        background-image: url("../../assets/icons/icon-checkbox.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &-caption {
      font-size: 18px;
      line-height: 23px;
      color: #FFFFFF;
      a {
        color: #FFFFFF;
        text-decoration: none;
        border-bottom: 1px solid white;
        cursor: pointer;
        &:hover {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
