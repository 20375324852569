<template>
  <section v-if="menu.totalCount !== 0 || menu.totalCount === 0 && isFiltering" id="menu" class="menu container">
    <div class="menu__headline">
      <img src="../../assets/icons/icon-menu.svg" alt="menu-icon" />
      <div class="menu__headline-title">Меню</div>
    </div>
    <div class="menu__tags">
      <span
        v-for="tag in tags"
        :key="tag.id"
        class="menu__tag"
        :class="{ 'menu__tag--active' : activeTags.includes(tag.id) }"
        @click="addTag(tag.id)"
      >
        <span>{{ tag.name }}</span>
        <img
          v-if="activeTags.includes(tag.id)"
          src="../../assets/icons/icon-delete.svg"
          alt="icon-delete"
          class="menu__tag-delete"
          @click.stop="deleteTag()"
        />
      </span>
    </div>
    <div class="menu__list" v-if="menu.totalCount !== 0">
      <CardMenu class="menu__item" v-for="(dish, index) in menu.items" :key="index" :dish="dish" />
    </div>
    <div class="menu__empty" v-else>Ничего не найдено</div>
    <v-pagination
      v-if="menu.totalCount > itemsOnPage"
      v-model="currentPage"
      :pages="Math.ceil(menu.totalCount / itemsOnPage)"
      :range-size="1"
      active-color="#FFFFFF"
      :hideFirstButton="true"
      :hideLastButton="true"
      @update:modelValue="paginate"
    />
  </section>
</template>

<script>
import CardMenu from '../CardMenu'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SectionMenu',
  components: { CardMenu, VPagination },
  props: {
    displayWidth: {
      type: Number
    }
  },
  data: () => ({
    currentPage: 1,
    itemsOnPage: 20,
    activeTags: [],
    isFiltering: false
  }),
  computed: {
    ...mapGetters('landing', ['menu', 'tags', 'selectedCity'])
  },
  watch: {
    selectedCity () {
      this.getMenu([0, this.itemsOnPage, []])
    }
  },
  methods: {
    ...mapActions('landing', ['getMenu', 'getTags']),
    getInitMenu () {
      return this.getMenu([0, this.itemsOnPage, this.activeTags])
    },
    paginate () {
      window.location.href = '#menu'
      setTimeout(() => {
        this.getMenu([this.currentPage - 1, this.itemsOnPage, this.activeTags])
      }, 500)
    },
    addTag (tagId) {
      if (!this.isFiltering) this.isFiltering = true
      this.activeTags = []
      this.activeTags.push(tagId)
      this.getInitMenu()
    },
    deleteTag () {
      this.activeTags = []
      this.getInitMenu()
        .then(() => {
          if (!this.activeTags.length) this.isFiltering = false
        })
    },
    setItemsOnPage () {
      if (this.displayWidth > 1440) {
        this.itemsOnPage = 20
      } else if (this.displayWidth <= 1440 && this.displayWidth > 1024) {
        this.itemsOnPage = 15
      } else if (this.displayWidth <= 1024 && this.displayWidth > 600) {
        this.itemsOnPage = 10
      } else {
        this.itemsOnPage = 5
      }
    }
  },
  mounted () {
    this.setItemsOnPage()
    this.getInitMenu()
    this.getTags()
    const scrollContainer = document.querySelector('.menu__tags')
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', (evt) => {
        evt.preventDefault()
        scrollContainer.scrollLeft += evt.deltaY
      })
    }
  }
  // unmounted () {
  //   const scrollContainer = document.querySelector('.menu__tags')
  //   scrollContainer.removeEventListener('wheel', null)
  // }
}
</script>

<style lang="scss">
@import "src/assets/styles/vars";
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__headline {
    text-align: center;
    @media (max-width: 600px) {
      img {
        width: 32px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }
    &-title {
      font-size: $h2-font-size;
      text-transform: uppercase;
      margin-bottom: 20px;
      @media (max-width: 600px) {
        font-size: $h2-font-size;
        margin-bottom: 0;
      }
    }
  }
  &__tags {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 10px 0;
    overflow-x: auto;
    margin-bottom: 10px;
    &::-webkit-scrollbar {
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: $tertiary-color;
      border-radius: 20px;
    }
    @media (max-width: 1440px) {
      max-width: 1090px;
    }
    @media (max-width: 1024px) {
      max-width: 712px;
    }
    @media (max-width: 600px) {
      max-width: 100%;
      margin: 0 auto 10px;
    }
  }
  &__tag {
    padding: 10px 20px;
    background: $primary-color;
    border-radius: 20px;
    cursor: pointer;
    span {
      font-weight: 300;
      font-size: $primary-font-size;
      white-space: nowrap;
      @media (max-width: 600px) {
        font-size: $primary-font-size;
      }
    }
    &:hover {
      background: $primary-hover-color;

      span {
        color: $black;
      }
    }
    &--active {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 10px;
      background: $accent-color;
      cursor: auto;
      img {
        cursor: pointer;
      }
      span {
        color: $accent-opposite-color;
      }
      &:hover {
      background: $accent-color;
        span {
          color: $white;
        }
      }
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 42px;
    row-gap: 30px;
    margin-bottom: 60px;
    @media (max-width: 1440px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__item {
    max-width: 315px;
    width: 100%;
    @media (max-width: 320px) {
      max-width: 300px;
    }
  }
  &__empty {
    max-width: 1440px;
    width: 100%;
    display: flex;
    font-size: $primary-font-size;
    @media (max-width: 1440px) {
      max-width: 1090px;
    }
    @media (max-width: 1024px) {
      max-width: 712px;
    }
    @media (max-width: 600px) {
      max-width: 335px;
    }
  }
}
.Pagination {
  .Page {
    width: 42px !important;
    height: 42px !important;
    font-size: $primary-font-size;
    &:hover {
      border: none;
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
  .Page-active {
    border-color: $accent-color !important;
    color: $accent-color;
    &:hover {
      background-color: $primary-color !important;
    }
  }
}
</style>
