import { createStore } from 'vuex'
import landing from '@/store/modules/landing'
import auth from '@/store/modules/auth'
import profile from '@/store/modules/profile'

export default createStore({
  modules: {
    landing,
    auth,
    profile
  }
})
