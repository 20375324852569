<template>
  <div class="modal" @click.self="$emit('close')">
    <div class="modal__wrapper" :style="style">
      <div class="modal__close" @click="$emit('close')">
        <img src="../assets/icons/auth-icon-close.svg" alt="icon-close">
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    style: String
  },
  data: () => ({
    items: [
      {
        title: 'г. Кострома',
        icon: require('../assets/icons/point-map.svg')
      },
      {
        title: 'г. Кострома',
        icon: require('../assets/icons/point-map.svg')
      },
      {
        title: 'г. Кострома',
        icon: require('../assets/icons/point-map.svg')
      }]
  })
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &__wrapper {
    position: relative;
    top: 0;
    height: 90%;
    width: 80%;
    background-color: $white;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @media (max-width: 600px) {
      width: 95%;
    }
    @media (max-width: 600px) {
      width: 100%;
      min-height: 100vh;
      padding: 15px;
      border-radius: 0;
    }
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    z-index: 2;
    @media (max-width: 600px) {
      right: 12px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
