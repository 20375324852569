<template>
  <div :id="item.id" :class="'selector-item ' + (activeId === item.id ? 'selector-item--active' : '')" @click="$emit('set-active', item.id)">
    <div class="selector-item-icon"><MapPoint/></div>
    <div class="selector-item-desc">
      <span v-if="item.alt"
            class="selector-item-desc-alt">
        {{ item.alt }}
      </span>
      <span v-if="item.title"
            class="selector-item-desc-title">
        {{ item.title }}
      </span>
    </div>
  </div>
</template>

<script>
import { MapPoint } from '@/components/vector-icons'
export default {
  name: 'SelectorItem',
  components: {
    MapPoint
  },
  props: {
    icon: String,
    item: Object,
    activeId: {
      type: Number,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";
.selector-item {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 5px;
  cursor: pointer;
  max-width: 345px;
  width: 100%;
  box-sizing: border-box;

  &-icon {
    height: 25px;
    fill: $accent-color;
  }

  &-desc {
    display: flex;
    flex-direction: column;
    &-alt {
      font-size: $tertiary-font-size;
      color: $background-color;
    }
    &-title {
      font-size: $secondary-font-size;
      color: $black;
    }
  }

  &:hover {
    background-color: $primary-color;
  }
  &--active {
    background-color: $accent-color !important;
    * {
      color: $white !important;
      fill: $white !important;
    }
  }
}</style>
