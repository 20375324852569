<template>
  <section id="about" class="banner container">
    <div class="banner__info">
      <img class="banner__logo" src="../../assets/icons/logo.svg" alt="logo" />
      <h1 class="banner__info-title">Кафе ГОРОD - любимое место в городе! </h1>
      <div class="banner__info-content info-content">
        <div class="info-content__description">Мы сделаем Ваш завтрак, обед или ужин разнообразным, вкусным и недорогим!</div>
        <div class="info-content__list">
          <div
            class="info-content__item"
            v-for="(item, index) in characteristics"
            :key="index"
          >
            <div class="info-content__item-group">
              <img class="info-content__item-icon" src="../../assets/icons/characteristic.svg" alt="characteristic-icon" />
              <span class="info-content__item-title">{{ item.title }}</span>
            </div>
            <span class="info-content__item-value">{{ item.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <transition>
      <div v-if="isHideVideo" class="banner__slider">
        <carousel ref="myCarousel" :settings="settings" @slide-end="setActiveSlide">
          <slide v-for="slide in 2" :key="slide">
            <div
              class="banner__slide"
              :style="{ backgroundImage: `url(${require(`../../assets/images/banner-image-${slide}.jpg`)})` }"
            ></div>
          </slide>
          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>
    </transition>
    <video v-if="!isHideVideo" ref="videoBanner" class="video-banner" muted autoplay preload>
      <source src="../../assets/video/town-banner.mp4" type="video/mp4">
    </video>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
export default {
  name: 'SectionBanner',
  components: {
    Carousel,
    Slide,
    Pagination
  },
  data: () => ({
    slide: 0,
    characteristics: [
      { title: 'Блюд в меню', value: '300+' },
      { title: 'Кафе в сети', value: '5+' },
      { title: 'Гостей в день', value: '500+' }
    ],
    settings: {
      autoplay: 6000,
      itemsToShow: 1,
      wrapAround: true
    },
    isHideVideo: false
  }),
  methods: {
    setActiveSlide (data) {
      this.slide = data.currentSlideIndex
      this.$refs.myCarousel.slideTo(data.currentSlideIndex)
    }
  },
  mounted () {
    if (this.$refs.videoBanner) {
      this.$refs.videoBanner.addEventListener('ended', () => {
        this.isHideVideo = true
      })
    }
  }
  // beforeUnmount () {
  //   this.$refs.videoBanner.removeEventListener('ended', null)
  // }
}
</script>

<style lang="scss">
@import "src/assets/styles/vars";

.banner {
  display: flex;
  justify-content: space-between;
  gap: 60px;
  margin-top: 37px;
  @media (max-width: 1920px) {
    margin: 0 0 0 60px;
  }

  @media (max-width: 1280px) {
    margin: 0 0 0 30px;
  }

  @media (max-width: 720px) {
    margin: 0 0 0 15px;
  }

  @media (max-width: 600px) {
    margin: 37px 15px 0 !important;
  }
  &__logo {
    position: absolute;
    width: 245px;
    top: -20px;
    left: 60px;
    z-index: 3;
    @media (max-height: 800px) {
      width: 150px;
    }
    @media (max-width: 840px) {
      top: -30px;
      width: 120px;
    }
    @media (max-height: 775px) {
      z-index: -1;
    }
    @media (max-width: 600px) {
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &__info {
    position: relative;
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    padding-left: 60px;
    @media (max-width: 840px) {
      flex-basis: 85%;
    }
    @media (max-width: 600px) {
      flex-basis: 100%;
      padding-left: 0;
    }
    &-title {
      max-width: 650px;
      width: 100%;
      font-size: $h1-font-size;
      margin-bottom: 15px;
      @media (max-width: 1024px) {
        max-width: 500px;
        font-size: $primary-font-size;
      }
      @media (max-width: 840px) {
        max-width: 100%;
        margin-top: 50px;
      }
      @media (max-width: 390px) {
        font-size: $primary-font-size;
      }
    }

    .info-content {
      &__description {
        max-width: 650px;
        width: 100%;
        font-size: $h2-font-size;
        margin-bottom: 80px;
        @media (max-width: 1024px) {
          max-width: 500px;
        }
        @media (max-width: 840px) {
          max-width: 100%;
          font-weight: 300;
        }
      }
      &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        gap: 15px;
        @media (max-width: 840px) {
          margin-bottom: 50px;
        }
        @media (max-width: 390px) {
          gap: 5px;
        }
      }
      &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &-group {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &-icon {
          margin-bottom: 15px;
          @media (max-width: 1024px) {
            width: 90px;
          }
          @media (max-width: 390px) {
            width: 60px;
          }
        }
        &-title {
          font-size: $h2-font-size;
          text-align: center;
          @media (max-width: 1024px) {
            font-size: $primary-font-size;
          }
          @media (max-width: 390px) {
            font-size: $primary-font-size;
          }
        }
        &-value {
          font-size: $h1-font-size;
          @media (max-width: 1440px) {
            font-size: $h1-font-size;
          }
          @media (max-width: 1024px) {
            font-size: $h1-font-size;
          }
          @media (max-width: 390px) {
            font-size: $h2-font-size;
          }
        }
      }
    }
  }
  .video-banner {
    width: 50%;
    height: 100vh;
    object-fit: cover;
    @media (max-width: 840px) {
      display: none;
    }
  }
  &__slider {
    position: relative;
    width: 50%;
    opacity: 1;
    transition: .2s opacity ease-in-out;
    @media (max-width: 840px) {
      display: none;
    }
    .carousel__slide {
      width: 100% !important;
    }
    .carousel__pagination {
      position: relative;
      margin: -35px 0 20px;
      display: flex;
      gap: 16px;
    }
    .carousel__pagination-button {
      padding: 0;
      &:after {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: $primary-color;
      }
    }
    .carousel__pagination-button--active {
      &:after {
        background: $accent-color;
      }
    }
  }
  &__slide {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
  }
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease-in-out;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
