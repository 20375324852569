<template>
  <section id="delivery" class="delivery">
    <div class="delivery__wrapper container">
      <div class="delivery__content">
        <div class="delivery__description">
          <span class="text-medium">Кафе ГОРОD</span> создано для того, чтобы Ваш завтрак, обед или ужин был разнообразным, вкусным, и главное, доступным по цене!
          Наше меню включает мясные и рыбные блюда, супы, салаты и напитки. На десерт Вы всегда сможете подобрать что-то на свой вкус из широкого ассортимента кондитерских изделий и свежей выпечки.
          Специально для наших любимых гостей ежедневно с 18:00 и до закрытия действует акция "Счастливые часы", в рамках которой Вы можете приобрести любое блюдо из меню со скидкой 30%!
        </div>
        <div class="delivery__list">
          <div
            class="delivery__item"
            v-for="(item, index) in deliveryItems"
            :key="index"
          >
            <span class="delivery__item-title">{{ item.title }}</span>
            <span class="delivery__item-description">{{ item.description }}</span>
          </div>
        </div>
      </div>
      <div class="delivery__cards">
        <div class="delivery__card">
          <div class="delivery__card-title text-right">Высокое качество блюд</div>
          <img class="delivery__card-image" src="../../assets/images/delivery-image1.svg" alt="delivery icon" />
        </div>
        <div class="delivery__card">
          <div class="delivery__card-title">Самовывоз</div>
          <img class="delivery__card-image" src="../../assets/images/delivery-image2.svg" alt="delivery icon" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionDelivery',
  data: () => ({
    deliveryItems: [
      {
        title: 'Нас выбирают',
        description: 'Наши кафе расположены в местах, комфортных для Вас.'
      },
      {
        title: 'Нам доверяют',
        description: 'Наша репутация и Ваше доверие - главные ценности, которыми мы дорожим!'
      },
      {
        title: 'Нас любят',
        description: 'Наши повара готовят блюда с любовью. У нас по-домашнему вкусно!'
      }
    ]
  })
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/vars";
.delivery {
  background-color: $primary-color;
  padding: 30px;
  @media (max-width: 376px) {
    padding: 15px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media (max-width: 840px) {
      justify-content: start;
      flex-direction: column;
      gap: 30px;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-evenly;
    gap: 90px;
    @media (max-width: 840px) {
      flex-direction: column;
      gap: 40px;
    }
  }
  &__description {
    max-width: 870px;
    width: 100%;
    font-size: $primary-font-size;
    @media (max-width: 376px) {
      font-size: $primary-font-size;
    }
  }
  &__list {
    display: flex;
    gap: 40px;
    max-width: 950px;
    width: 100%;
    background-color: $primary-color;
    padding: 18px 20px 25px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.1);
    @media (max-width: 840px) {
      gap: 20px;
    }
    @media (max-width: 540px) {
      gap: 10px;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      padding: 20px;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    width: 100%;
    @media (max-width: 480px) {
      max-width: 100%;
    }
    &-title {
      font-weight: 500;
      font-size: $primary-font-size;
      margin-bottom: 10px;
      @media (max-width: 540px) {
        font-weight: 300;
        font-size: $primary-font-size;
      }
    }
    &-description {
      font-size: $secondary-font-size;
      color: $secondary-color;
      @media (max-width: 540px) {
        font-weight: 400;
        font-size: $secondary-font-size;
      }
    }
  }

  &__cards {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 97px;
    @media (max-width: 1024px) {
      width: 300px;
    }
    @media (max-width: 840px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      gap: 40px;
    }
    @media (max-width: 540px) {
      display: none;
      grid-template-columns: 1fr;
    }
  }
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 170px;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.3);
    border-radius: 5px;

    &-title {
      max-width: 350px;
      width: 100%;
      font-size: $primary-font-size;
      padding: 12px;
      @media (max-width: 1024px) {
        font-size: $primary-font-size;
      }
      @media (max-width: 376px) {
        font-size: $primary-font-size;
        max-width: 80%;
      }
    }
    .text-right {
      align-self: flex-end;
    }
    &-image {
      position: relative;
      bottom: 20px;
      right: 15px;
      @media (max-width: 1024px) {
        width: 250px;
      }
      @media (max-width: 840px) {
        width: 100%;
      }
    }
  }
}
</style>
