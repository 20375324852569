<template>
  <AuthPopup class="activation" @closeModal="$emit('closeModal')">
    <div v-if="!isLoading" class="auth-modal__form auth-modal__form--fullwidth">
      <div class="auth-modal__title activation__title">Выпустить виртуальную<br>карту</div>
      <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.fio }">
        <span class="auth-modal__label-name">ФИО</span>
        <input v-model="form.fio" type="text" class="auth-modal__label-input">
        <img v-if="validationResult && validationResult.fieldErrors.fio" class="auth-modal__label-icon" src="../../assets/icons/auth-icon-error.svg" alt="icon-error" />
        <span v-if="validationResult && validationResult.fieldErrors.fio" class="auth-modal__label-error">{{ validationResult.fieldErrors.fio[0] }}</span>
      </div>
      <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.birthDate }">
        <span class="auth-modal__label-name">Дата рождения</span>
        <InputDate v-model="form.birthDate" width="365px" class="desktop-date" />
        <input
          v-model="form.birthDate"
          type="date"
          class="mobile-date"
        >
        <img v-if="validationResult && validationResult.fieldErrors.birthDate" class="auth-modal__label-icon" src="../../assets/icons/auth-icon-error.svg" alt="icon-error" />
        <span v-if="validationResult && validationResult.fieldErrors.birthDate" class="auth-modal__label-error">{{ validationResult.fieldErrors.birthDate[0] }}</span>
      </div>
      <div
        class="auth-modal__checkbox"
        :class="{ 'auth-modal__checkbox--error' : validationResult && validationResult.fieldErrors.readPrivacyPolicy }"
      >
          <span
            class="auth-modal__checkbox-item"
            :class="{ 'auth-modal__checkbox-item--checked' : form.readPrivacyPolicy }"
            @click="form.readPrivacyPolicy = !form.readPrivacyPolicy"
          ></span>
        <span class="auth-modal__checkbox-caption">Даю <a class="link-policy" @click="$emit('openPolicy', 'sign-up')">согласие на обработку персональных данных</a></span>
      </div>
      <button class="auth-modal__button" @click="submit">Выпустить карту</button>
    </div>
    <div v-else class="center-wrapper">
      <Loader />
    </div>
  </AuthPopup>
</template>

<script>
import AuthPopup from '@/components/auth/AuthPopup.vue'
import InputDate from '@/components/profile/InputDate.vue'
import { mapActions } from 'vuex'
import Loader from '@/components/Loader.vue'

export default {
  name: 'VirtualCard',
  components: { Loader, AuthPopup, InputDate },
  data: () => ({
    form: {
      fio: null,
      birthDate: null,
      readPrivacyPolicy: false
    },
    validationResult: null,
    isLoading: true
  }),
  computed: {
    userId () {
      return localStorage.getItem('userId')
    }
  },
  methods: {
    ...mapActions('profile', ['getFormVirtualCard', 'submitVirtualCard', 'getCurrentCard']),
    submit () {
      this.isLoading = true
      const data = {
        ...this.form,
        id: this.userId,
        validationResult: {
          hasNestedFormErrors: false
        }
      }
      this.submitVirtualCard(data)
        .then(({ data }) => {
          this.validationResult = data.validationResult
          if (!Object.keys(this.validationResult.fieldErrors).length) {
            this.getCurrentCard()
            this.$emit('closeModal')
          }
        })
        .catch(error => error)
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  created () {
    this.getFormVirtualCard()
      .then(({ data }) => {
        this.form = JSON.parse(JSON.stringify(data))
      })
      .catch(error => error)
      .finally(() => {
        this.isLoading = false
      })
  }
}
</script>

<style scoped lang="scss">
.center-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activation {
  &__title {
    margin-bottom: 32px;
  }
  .auth-modal__checkbox {
    margin-top: 16px;
  }
  .auth-modal__button {
    margin-top: 32px;
    margin-bottom: 70px;
  }
}
</style>
