<template>
  <div class="card-news">
    <div class="card-news__wrapper">
      <div class="card-news__content" v-html="news.description"></div>
      <div class="card-news__image">
        <img v-if="news.imagePath" :src="news.imagePath" alt="image" />
      </div>
    </div>
    <img class="card-news__icon" :class="{ 'card-news__icon--second' : slide !== 1 }" :src="require(`../assets/images/news-image${slide}.svg`)" alt="news-image" />
  </div>
</template>

<script>
export default {
  name: 'CardNews',
  props: {
    news: {
      type: Object,
      required: true
    },
    slide: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/vars";
.card-news {
  margin: 10px 0;
  border-radius: 5px;
  min-width: 444px;
  height: 524px;
  padding: 10px;
  box-shadow: 0 0 11px 3px rgba(0, 0, 0, 0.1);
  // box-shadow: 0 0 10px 0 #A7A7A7;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1440px) {
    &:first-child {
      margin-left: 5px;
    }
  }
  @media (max-width: 480px) {
    min-width: 100%;
  }
  &__content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: $secondary-font-size;
    text-align: left;
    height: 68px;
    * {
      font-size: $primary-font-size;
      text-align: left;
    }
  }
  &__icon {
    align-self: flex-end;
    width: 50%;
    &--second {
      width: 30%;
      align-self: flex-end;
    }
  }
  &__image {
    width: 100%;
    height: 276px;
    overflow: hidden;
    margin-top: 5px;
    & > img {
      max-width: 100%;
      max-height: 276px;
      border-radius: 10px;
    }
  }
}
</style>
