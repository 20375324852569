import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import ProfilePage from '@/views/ProfilePage.vue'

const routes = [
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash
      }
    }
  },
  routes
})

export default router
