<template>
  <header class="header">
    <div class="header__wrapper container">
      <ul class="header__nav header__nav--desktop">
        <li v-for="(item, index) in navigation" :key="index">
          <router-link :to="{ name: 'Home', hash: item.link }">{{ item.title }}</router-link>
        </li>
        <SelectorItem v-if="city" :item="city" @click="$emit('open-modal')"/>
        <li v-if="!$cookies.get('token')" @click="$emit('openLogin')" class="icon-nav">
          <img src="../assets/icons/login.svg" alt="login">
        </li>
        <li v-if="$cookies.get('token')" @click="$router.push('/profile')" class="icon-nav">
          <img src="../assets/icons/profile.svg" alt="profile">
        </li>
        <li class="icon-nav" v-if="$route.path.includes('profile')" @click="logoutProfile">
          <img class="small" src="../assets/icons/logout.svg" alt="logout">
          <span>Выход</span>
        </li>
      </ul>
      <div class="header__nav header__nav--mobile" v-click-outside="onClickOutside" @click="showNav = !showNav">
        <div class="mobile-menu"><img src="../assets/icons/menu.svg" alt="menu" /></div>
        <ul class="mobile-nav" :class="{ 'mobile-nav--show' : showNav }">
          <li v-for="(item, index) in navigation" :key="index">
            <router-link :to="{ name: 'Home', hash: item.link }">{{ item.title }}</router-link>
          </li>
          <li v-if="!$cookies.get('token')" @click="$emit('openLogin')" class="icon-nav">
            <img src="../assets/icons/login.svg" alt="login">
            <span>Войти</span>
          </li>
          <li v-if="$cookies.get('token')" @click="$router.push('/profile')" class="icon-nav">
            <img src="../assets/icons/profile.svg" alt="profile">
            <span class="text-orange">Личный кабинет</span>
          </li>
          <li class="icon-nav" v-if="$route.path.includes('profile')" @click="logoutProfile">
            <img src="../assets/icons/logout.svg" alt="logout">
            <span>Выход</span>
          </li>
          <SelectorItem v-if="city" :item="city" @click="$emit('open-modal')"/>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import SelectorItem from '../components/selector/SelectorItem'
import { mapGetters, mapActions } from 'vuex'
import vClickOutside from 'click-outside-vue3'
export default {
  name: 'Header',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    SelectorItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    navigation: [
      { title: 'Меню', link: '#menu' },
      { title: 'Акции', link: '#info' },
      { title: 'Новости', link: '#news' },
      { title: 'Самовывоз и оплата', link: '#delivery' },
      { title: 'Контакты', link: '#contacts' },
      { title: 'Франшиза', link: '#franchise' },
      { title: 'О нас', link: '#about' }
    ],
    showNav: false
  }),
  computed: {
    ...mapGetters('landing', ['selectedCity']),
    city () {
      return this.items.find(el => el.id === Number(this.selectedCity))
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    logoutProfile () {
      this.logout()
        .finally(() => {
          this.$cookies.remove('token')
          this.$emit('exit')
        })
    },
    onClickOutside () {
      this.showNav = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";

.header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background-color: $white;
  z-index: 2;
  box-sizing: border-box;

  @media (max-width: 840px) {
    top: auto;
    bottom: 30px;
    right: 30px;
    width: 62px;
    height: 60px;
    padding: 18px 13px;
    background: $white;
    border-radius: 3px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }
  &__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    @media (max-width: 840px) {
      justify-content: center;
      padding-right: 0;
      margin: 0 15px!important;
    }
  }

  &__nav {
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    width: auto;
    justify-content: flex-end;
    gap: 15px;
    @media (max-width: 840px) {
      &--desktop {
        display: none;
      }
    }

    li {
      display: flex;
      align-items: center;
      font-size: $primary-font-size;
      white-space: nowrap;
      font-weight: 300;
      cursor: pointer;
      &:first-child {
        font-weight: 500;
        a, * {
          font-weight: 500;
        }
      }
      & a, * {
        font-weight: 300;
        font-size: $primary-font-size;
        cursor: pointer;
        color: $black;
        text-decoration: none;
        white-space: nowrap;
        @media (max-width: 1024px) {
          font-size: $secondary-font-size;
        }

        &:hover {
          color: $black;
        }
      }
    }
    &--mobile {
      position: relative;
      display: none;
      z-index: 3;
      @media (max-width: 840px) {
        display: block;
        .mobile-menu {
          display: flex;
          justify-content: flex-end;
        }
        .mobile-nav {
          display: none;
          flex-direction: column;
          align-items: flex-start;
          position: absolute;
          bottom: 40px;
          right: 0;

          background: $white;
          padding: 10px;
          border-radius: 5px;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
          &--show {
            display: flex;
          }
          li {
            padding: 10px;
            &:active {
              background: $primary-color;
              border-radius: 20px;
            }
          }
          .selector-item {
            border-radius: 20px;
          }
          li, a {
            border-radius: 5px;
            cursor: pointer;
            max-width: 345px;
            width: 100%;
            box-sizing: border-box;
            user-select: none;
          }
        }
      }
    }
  }
}

.icon-nav {
  padding: 10px 0;
  display: flex;
  gap: 16px;
  span {
    font-size: $secondary-font-size;
  }
  @media (max-width: 600px) {
    padding: 10px;
  }
  img {
    height: 33px;
    &.small {
      height: 22px;
    }
    @media (max-width: 600px) {
      height: 22px;
    }
  }
  .text-orange {
    color: $link;
  }
}
</style>
