<template>
  <Header
    :items="listCities"
    @open-modal="modeSystemModal = 'city'"
    @open-login="modeModal = 'login'"
    @exit="$router.push('/')"
  />
  <router-view/>
  <Footer v-if="!$route.path.includes('profile')" @openPolicy="openPolicyFooter" />
  <Modal v-if="modeSystemModal === 'city'" @close="closeMap" :style="'width: 381px; height: 90vh'" class="small-modal">
    <Selector :items="listCities" :on-select="closeMap"/>
  </Modal>
  <Modal v-if="modeSystemModal === 'cookie'" @close="agreeCookie" :style="'max-width: 830px; max-height: 700px'">
    <Cookie @open-policy="modeSystemModal = 'policy'" @close="agreeCookie"/>
  </Modal>
  <Modal v-if="modeSystemModal === 'policy'" @close="closePolicy">
    <Policy :mode="modePolicy"/>
  </Modal>
  <RegistrationForm
    v-if="modeModal === 'registration'"
    @open-login="modeModal = 'login'"
    @close-modal="modeModal = 'none'"
    @open-policy="openPolicyRegistration"
  />
  <LoginForm
    v-if="modeModal === 'login'"
    @open-registration="modeModal = 'registration'"
    @open-recovery-password="modeModal = 'recovery-password'"
    @close-modal="modeModal = 'none'"
    @success-login="modeModal = 'none'; $router.push('/profile')"
  />
  <RecoveryPasswordForm
    v-if="modeModal === 'recovery-password'"
    @open-login="modeModal = 'login'"
    @close-modal="modeModal = 'none'"
  />
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import Modal from '@/components/Modal.vue'
import Selector from '@/components/selector/Selector.vue'
import { mapGetters, mapActions } from 'vuex'
import RegistrationForm from '@/components/auth/forms/RegistrationForm.vue'
import LoginForm from '@/components/auth/forms/LoginForm.vue'
import RecoveryPasswordForm from '@/components/auth/forms/RecoveryPasswordForm.vue'
import Cookie from '@/components/popups/Cookie.vue'
import Policy from '@/components/popups/Policy.vue'
export default {
  name: 'App',
  components: {
    Cookie,
    Policy,
    RecoveryPasswordForm,
    LoginForm,
    RegistrationForm,
    Footer,
    Header,
    Modal,
    Selector
  },
  data: () => ({
    modeSystemModal: 'none',
    isOpenModalCity: false,
    isOpenModalCookie: false,
    modeModal: 'none',
    isRegistration: false,
    modePolicy: 'policy'
  }),
  computed: {
    ...mapGetters('landing', ['cities', 'selectedCity']),
    listCities () {
      return this.cities.map(x => { return { id: x.id, title: x.name } })
    }
  },
  watch: {
    modeModal (value) {
      if (value !== 'none') document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'auto'
    },
    modeSystemModal (value) {
      if (value !== 'none') document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'auto'
    }
  },
  methods: {
    ...mapActions('landing', ['getCities']),
    ...mapActions('auth', ['checkAuth']),
    closeMap () {
      this.addressMap = []
      this.modeSystemModal = 'none'
    },
    openModal () {
      this.modeSystemModal = 'city'
    },
    agreeCookie () {
      localStorage.setItem('read_cookie', 'read')
      this.modeSystemModal = 'none'
      if (!this.selectedCity) this.openModal()
    },
    closePolicy () {
      if (!localStorage.getItem('read_cookie')) this.modeSystemModal = 'cookie'
      else {
        this.modeSystemModal = 'none'
        if (this.isRegistration) {
          this.isRegistration = false
          this.modeModal = 'registration'
        }
      }
    },
    openPolicyRegistration (event) {
      this.modeModal = 'none'
      this.isRegistration = true
      this.modePolicy = event
      this.modeSystemModal = 'policy'
    },
    openPolicyFooter () {
      this.modePolicy = 'policy'
      this.modeSystemModal = 'policy'
    }
  },
  created () {
    this.getCities()
    if (!localStorage.getItem('read_cookie')) this.modeSystemModal = 'cookie'
    if (this.$cookies.get('token')) {
      this.checkAuth()
        .catch(() => {
          if (localStorage.getItem('userId')) localStorage.removeItem('userId')
          this.$cookies.remove('token')
          if (window.location !== '/') window.location = '/'
        })
    }
  }
}
</script>

<style lang="scss">
@import "assets/styles/main";
.vue-notification-group {
  top: 15px !important;
  right: 15px !important;
}
.small-modal {
  .modal__close img {
    width: 24px;
    height: 24px;
  }
}
</style>
