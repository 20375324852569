<template>
  <AuthPopup class="activation" @closeModal="$emit('closeModal')">
    <div v-if="!isLoading" class="auth-modal__form auth-modal__form--fullwidth">
      <div class="auth-modal__title activation__title">Активировать пластиковую<br>карту</div>
      <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.cardId }">
        <span class="auth-modal__label-name">Номер пластиковой карты</span>
        <input v-model="form.cardId" type="tel" @input="changeCard" class="auth-modal__label-input">
        <img v-if="validationResult && validationResult.fieldErrors.cardId" class="auth-modal__label-icon" src="../../assets/icons/auth-icon-error.svg" alt="icon-error" />
        <span v-if="validationResult && validationResult.fieldErrors.cardId" class="auth-modal__label-error">{{ validationResult.fieldErrors.cardId[0] }}</span>
      </div>
      <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.fio }">
        <span class="auth-modal__label-name">ФИО</span>
        <input v-model="form.fio" type="text" class="auth-modal__label-input">
        <img v-if="validationResult && validationResult.fieldErrors.fio" class="auth-modal__label-icon" src="../../assets/icons/auth-icon-error.svg" alt="icon-error" />
        <span v-if="validationResult && validationResult.fieldErrors.fio" class="auth-modal__label-error">{{ validationResult.fieldErrors.fio[0] }}</span>
      </div>
      <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.birthDate }">
        <span class="auth-modal__label-name">Дата рождения</span>
        <InputDate v-model="form.birthDate" width="365px" class="desktop-date" />
        <input
          v-model="form.birthDate"
          type="date"
          class="mobile-date"
        >
        <img v-if="validationResult && validationResult.fieldErrors.birthDate" class="auth-modal__label-icon" src="../../assets/icons/auth-icon-error.svg" alt="icon-error" />
        <span v-if="validationResult && validationResult.fieldErrors.birthDate" class="auth-modal__label-error">{{ validationResult.fieldErrors.birthDate[0] }}</span>
      </div>
      <div
        class="auth-modal__checkbox"
        :class="{ 'auth-modal__checkbox--error' : validationResult && validationResult.fieldErrors.readPrivacyPolicy }"
        @click="form.readPrivacyPolicy = !form.readPrivacyPolicy"
      >
          <span
            class="auth-modal__checkbox-item"
            :class="{ 'auth-modal__checkbox-item--checked' : form.readPrivacyPolicy }"
          ></span>
        <span class="auth-modal__checkbox-caption">Даю согласие на обработку персональных данных</span>
      </div>
      <button class="auth-modal__button" @click="submit">Активировать карту</button>
    </div>
    <div v-else class="center-wrapper">
      <Loader />
    </div>
  </AuthPopup>
</template>

<script>
import AuthPopup from '@/components/auth/AuthPopup.vue'
import { mapActions } from 'vuex'
import InputDate from '@/components/profile/InputDate.vue'
import Loader from '@/components/Loader.vue'

export default {
  name: 'ActivationCard',
  components: { Loader, InputDate, AuthPopup },
  data: () => ({
    form: {
      cardId: '',
      fio: '',
      birthDate: '',
      readPrivacyPolicy: false
    },
    validationResult: null,
    isLoading: true
  }),
  computed: {
    userId () {
      return localStorage.getItem('userId')
    }
  },
  methods: {
    ...mapActions('profile', ['getFormCurrentCard', 'submitCurrentCard', 'getCurrentCard']),
    changeCard () {
      const x = this.form.cardId.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})/)
      this.form.cardId = x[1] + (x[2] ? ' ' + x[2] : '')
    },
    submit () {
      this.isLoading = true
      const data = {
        ...this.form,
        cardId: this.form.cardId ? this.form.cardId.replace(/\s/g, '') : null,
        id: this.userId,
        validationResult: {
          hasNestedFormErrors: false
        }
      }
      this.submitCurrentCard(data)
        .then(({ data }) => {
          this.validationResult = data.validationResult
          if (!Object.keys(this.validationResult.fieldErrors).length) {
            this.getCurrentCard()
            this.$emit('closeModal')
          }
        })
        .catch(error => error)
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  created () {
    this.getFormCurrentCard()
      .then(({ data }) => {
        this.form = JSON.parse(JSON.stringify(data))
      })
      .catch(error => error)
      .finally(() => {
        this.isLoading = false
      })
  }
}
</script>

<style scoped lang="scss">
.center-wrapper {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activation {
  &__title {
    margin-bottom: 32px;
  }
  .auth-modal__checkbox {
    margin-top: 16px;
  }
  .auth-modal__button {
    margin-top: 32px;
    margin-bottom: 70px;
  }
}
</style>
