<template>
  <AuthPopup @close-modal="$emit('closeModal')">
    <div class="auth-modal__form">
      <div class="auth-modal__title">Регистрация</div>
      <div class="auth-modal__caption" :class="{ 'auth-modal__caption--mb24' : step === 2 }" v-if="step !== 3">
        У вас уже есть запись? <span class="link" @click="$emit('openLogin')">Войти</span>
      </div>
      <template v-if="step === 1">
        <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.phone }">
          <span class="auth-modal__label-name">Телефон</span>
          <input v-model="form.phone" @input="changePhone" type="tel" class="auth-modal__label-input">
          <img v-if="validationResult && validationResult.fieldErrors.phone" class="auth-modal__label-icon" src="../../../assets/icons/auth-icon-error.svg" alt="icon-error" />
          <span v-if="validationResult && validationResult.fieldErrors.phone" class="auth-modal__label-error">{{ validationResult.fieldErrors.phone[0] }}</span>
        </div>
        <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.password }">
          <span class="auth-modal__label-name">Пароль</span>
          <input v-model="form.password" :type="isShowPassword ? 'text' : 'password'" class="auth-modal__label-input">
          <img
            v-if="form.password.length"
            class="auth-modal__label-icon auth-modal__label-icon--clickable"
            :src="require(`../../../assets/icons/auth-icon-eye${validationResult && validationResult.fieldErrors.password ? '-error' : ''}.svg`)"
            alt="icon-error"
            @click="isShowPassword = !isShowPassword"
          />
          <span
            class="auth-modal__label-text"
            :class="{ 'auth-modal__label-text--error' : validationResult && validationResult.fieldErrors.password }"
          >
            Пароль должен содержать не менее 8 символов, минимум одну заглавную букву, минимум один спец символ<br>(№,@,%,!,*,&)
          </span>
        </div>
        <div
          class="auth-modal__checkbox"
          :class="{ 'auth-modal__checkbox--error' : validationResult && validationResult.fieldErrors.acceptPolicy }"
        >
          <span
            class="auth-modal__checkbox-item"
            :class="{ 'auth-modal__checkbox-item--checked' : form.acceptPolicy }"
            @click="form.acceptPolicy = !form.acceptPolicy"
          ></span>
          <span class="auth-modal__checkbox-caption">
            Продолжая, вы даете <a class="link-policy" @click="$emit('openPolicy', 'sign-up')">согласие на обработку персональных данных</a> и <a class="link-policy" @click="$emit('openPolicy', 'other-person')">согласие на передачу обработки персональных данных третьему лицу</a>
          </span>
        </div>
        <button class="auth-modal__button auth-modal__button--small" @click="registration">Зарегистрироваться</button>
      </template>
      <template v-else-if="step === 2">
        <div class="auth-modal__caption">
          Для подтверждения на телефон  {{ `${this.form.phone.slice(0, 2)} ${this.form.phone.slice(3, 6)} *** ** ${this.form.phone.slice(14)}` }}<br>был отправлен код<br><span class="link" :class="{ 'link--disabled' : !isResendCode }" @click="sendNewCode">Отправить код еще раз {{ !isResendCode ? `(${timer})` : '' }}</span>
        </div>
        <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.code }">
          <span class="auth-modal__label-name">Код подтверждения</span>
          <input v-model="code" type="tel" class="auth-modal__label-input">
          <span v-if="validationResult && validationResult.fieldErrors.code" class="auth-modal__label-error">{{ validationResult.fieldErrors.code[0] }}</span>
        </div>
        <button class="auth-modal__button auth-modal__button--mt26" @click="confirm">Подтвердить</button>
      </template>
      <template v-else>
        <div class="auth-modal__caption auth-modal__caption--mt22">Вы успешно зарегистрировались!<br>Теперь вы можете войти в личный кабинет</div>
        <button class="auth-modal__button auth-modal__button--mt0" @click="$emit('closeModal'); $router.push('/profile')">Войти в личный кабинет</button>
      </template>
    </div>
  </AuthPopup>
</template>

<script>
import AuthPopup from '@/components/auth/AuthPopup.vue'
import { mapActions } from 'vuex'

export default {
  name: 'RegistrationForm',
  components: { AuthPopup },
  data: () => ({
    step: 1,
    form: {
      phone: '',
      password: '',
      acceptPolicy: false
    },
    isShowPassword: false,
    userId: null,
    code: '',
    validationResult: null,
    isResendCode: false,
    timer: '1:00'
  }),
  methods: {
    ...mapActions('auth', ['registrationSubmit', 'registrationConfirm', 'resendCode']),
    changePhone () {
      const x = this.form.phone.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
      if (x.input === '') {
        this.form.phone = ''
        return
      }
      if (!x[2] && x[1] !== '') {
        this.form.phone = x[1] === '8' || x[1] === '7' ? '+7' : '+7' + x[1]
      } else {
        this.form.phone = '+' + (x[2] ? x[1] + ' ' + x[2] : x[1]) + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '') + (x[5] ? ' ' + x[5] : '')
      }
    },
    registration () {
      const data = {
        validationResult: {
          hasNestedFormErrors: true
        },
        id: null,
        phone: this.form.phone.replace(/\s/g, ''),
        password: this.form.password,
        acceptPolicy: this.form.acceptPolicy
      }
      this.registrationSubmit(data)
        .then(({ data }) => {
          this.userId = data.id
          if (data.validationResult.hasErrors) {
            this.validationResult = data.validationResult
          } else {
            localStorage.setItem('userId', data.id)
            this.step = 2
            this.startTimer()
          }
        })
        .catch((error) => error)
    },
    startTimer () {
      setInterval(() => {
        const [minutes, seconds] = this.timer.split(':')
        if (minutes === '1') this.timer = '0:59'
        else if (Number(seconds) === 1) {
          this.stopTimer()
          this.timer = '1:00'
        } else {
          const currentSeconds = Number(seconds) - 1
          this.timer = `0:${currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds}`
        }
      }, 1000)
    },
    stopTimer () {
      this.isResendCode = true
    },
    confirm () {
      const data = {
        validationResult: {
          hasNestedFormErrors: true
        },
        token: null,
        userId: this.userId,
        code: this.code
      }
      this.registrationConfirm(data)
        .then(({ data }) => {
          if (data.validationResult.hasErrors) {
            this.validationResult = data.validationResult
          } else {
            document.cookie = `token=${data.token}`
            this.step = 3
          }
        })
        .catch((error) => error)
    },
    sendNewCode () {
      if (this.isResendCode) {
        this.isResendCode = false
        this.resendCode()
        this.startTimer()
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
