import axios from 'axios'
import { toast } from 'vue3-toastify'
const API_URL = process.env.VUE_APP_API_URL

const api = axios.create({
  baseURL: API_URL,
  timeout: 30000
})

api.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error.code === 'ECONNABORTED') {
    toast.error('Ошибка: превышено время ожидания ответа', {
      position: toast.POSITION.TOP_RIGHT
    })
  } else {
    toast.error(`Error ${error.response.status}: ${error.response.data.message}`, {
      position: toast.POSITION.TOP_RIGHT
    })
  }
  return Promise.reject(error)
})

export default api
