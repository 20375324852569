<template>
 <AuthPopup>
   <div class="auth-modal__form auth-modal__form--fullwidth">
     <div class="auth-modal__title">Восстановление пароля</div>
     <div v-if="step !== 4" class="auth-modal__caption auth-modal__caption--mb24">Вспомнили пароль? <span class="link" @click="$emit('openLogin')">Войти</span></div>
     <template v-if="step === 1">
       <div class="auth-modal__caption">Для восстановления пароля укажите телефон, используемый для регистрации. На него придет код для сбора пароля</div>
       <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.phone }">
         <span class="auth-modal__label-name">Телефон</span>
         <input v-model="form.phone" @input="changePhone" type="tel" class="auth-modal__label-input">
         <img v-if="validationResult && validationResult.fieldErrors.phone" class="auth-modal__label-icon" src="../../../assets/icons/auth-icon-error.svg" alt="icon-error" />
         <span v-if="validationResult && validationResult.fieldErrors.phone" class="auth-modal__label-error">{{ validationResult.fieldErrors.phone[0] }}</span>
       </div>
       <button class="auth-modal__button auth-modal__button--mt26" @click="sendCode">Отправить код</button>
     </template>
     <template v-else-if="step === 2">
       <div class="auth-modal__caption">Код отправлен на телефон {{ `${this.form.phone.slice(0, 2)} ${this.form.phone.slice(3, 6)} *** ** ${this.form.phone.slice(14)}` }}. Для сброса пароля введите код. <br><span class="link" :class="{ 'link--disabled' : !isResendCode }" @click="sendNewCode">Отправить еще раз {{ !isResendCode ? `(${timer})` : '' }}</span></div>
       <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.code }">
         <span class="auth-modal__label-name">Код сброса</span>
         <input v-model="form.code" type="tel" class="auth-modal__label-input">
         <span v-if="validationResult && validationResult.fieldErrors.code" class="auth-modal__label-error">{{ validationResult.fieldErrors.code[0] }}</span>
       </div>
       <button class="auth-modal__button auth-modal__button--mt26" @click="resetPassword">Сбросить пароль</button>
     </template>
     <template v-else-if="step === 3">
       <div class="auth-modal__caption">Для завершения сброса создайте и подтвердите новый пароль</div>
       <div class="auth-modal__label" :class="{ 'auth-modal__label--error' : validationResult && validationResult.fieldErrors.newPassword }">
         <span class="auth-modal__label-name">Новый пароль</span>
         <input v-model="form.newPassword" :type="isShowPassword ? 'text' : 'password'" class="auth-modal__label-input">
         <img
           v-if="form.newPassword.length"
           class="auth-modal__label-icon auth-modal__label-icon--clickable"
           :src="require(`../../../assets/icons/auth-icon-eye${validationResult && validationResult.fieldErrors.newPassword ? '-error' : ''}.svg`)"
           alt="icon-error"
           @click="isShowPassword = !isShowPassword"
         />
         <span
           class="auth-modal__label-text"
           :class="{ 'auth-modal__label-text--error' : validationResult && validationResult.fieldErrors.newPassword }"
         >
            Пароль должен содержать не менее 8 символов, минимум одну заглавную букву, минимум один спец символ<br>(№,@,%,!,*,&)
          </span>
       </div>
       <button class="auth-modal__button auth-modal__button--mt26" @click="sendNewPassword">Подтвердить новый  пароль</button>
     </template>
     <template v-else>
       <div class="auth-modal__caption auth-modal__caption--mt22">Ваш пароль успешно изменен</div>
       <button class="auth-modal__button auth-modal__button--mt0" @click="$emit('openLogin')">Вернуться на экран входа</button>
     </template>
   </div>
 </AuthPopup>
</template>

<script>
import AuthPopup from '@/components/auth/AuthPopup.vue'
import { mapActions } from 'vuex'

export default {
  name: 'RecoveryPasswordForm',
  components: {
    AuthPopup
  },
  data: () => ({
    step: 1,
    form: {
      phone: '',
      code: '',
      newPassword: ''
    },
    isShowPassword: false,
    userId: null,
    validationResult: null,
    isResendCode: false,
    timer: '1:00'
  }),
  methods: {
    ...mapActions('auth', ['recoveryPasswordSubmit', 'recoveryPasswordConfirm', 'resendCode']),
    changePhone () {
      const x = this.form.phone.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
      if (x.input === '') {
        this.form.phone = ''
        return
      }
      if (!x[2] && x[1] !== '') {
        this.form.phone = x[1] === '8' || x[1] === '7' ? '+7' : '+7' + x[1]
      } else {
        this.form.phone = '+' + (x[2] ? x[1] + ' ' + x[2] : x[1]) + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '') + (x[5] ? ' ' + x[5] : '')
      }
    },
    startTimer () {
      setInterval(() => {
        const [minutes, seconds] = this.timer.split(':')
        if (minutes === '1') this.timer = '0:59'
        else if (Number(seconds) === 1) {
          this.stopTimer()
          this.timer = '1:00'
        } else {
          const currentSeconds = Number(seconds) - 1
          this.timer = `0:${currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds}`
        }
      }, 1000)
    },
    stopTimer () {
      this.isResendCode = true
    },
    sendNewCode () {
      if (this.isResendCode) {
        this.isResendCode = false
        this.resendCode()
        this.startTimer()
      }
    },
    sendCode () {
      const data = {
        validationResult: {
          hasNestedFormErrors: true
        },
        id: null,
        phone: this.form.phone.replace(/\s/g, '')
      }
      this.recoveryPasswordSubmit(data)
        .then(({ data }) => {
          this.userId = data.id
          if (data.validationResult.hasErrors) {
            this.validationResult = data.validationResult
          } else {
            localStorage.setItem('userId', data.id)
            this.step = 2
            this.startTimer()
          }
        })
        .catch((error) => error)
    },
    resetPassword () {
      const data = {
        validationResult: {
          hasNestedFormErrors: true
        },
        userId: this.userId,
        code: this.form.code,
        newPassword: null,
        token: null
      }
      this.recoveryPasswordConfirm(data)
        .then(({ data }) => {
          this.validationResult = data.validationResult
          if (!this.validationResult.fieldErrors.code) {
            this.step = 3
          }
        })
        .catch((error) => error)
    },
    sendNewPassword () {
      const data = {
        validationResult: {
          hasNestedFormErrors: true
        },
        userId: this.userId,
        code: this.form.code,
        newPassword: this.form.newPassword,
        token: null
      }
      this.recoveryPasswordConfirm(data)
        .then(({ data }) => {
          if (data.validationResult.hasErrors) {
            this.validationResult = data.validationResult
          } else {
            this.step = 4
          }
        })
        .catch((error) => error)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
