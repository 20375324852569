<template>
  <div class="auth-modal" @click.self="$emit('closeModal')">
    <div class="auth-modal__window">
     <div class="auth-modal__close" @click="$emit('closeModal')">
       <img src="../../assets/icons/auth-icon-close.svg" alt="icon-close">
     </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthPopup'
}
</script>

<style lang="scss">
@import "src/assets/styles/vars";
.auth-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  &__window {
    position: relative;
    top: 0;
    min-height: 533px;
    width: 830px;
    background-color: $white;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 50px 0 30px;
    box-sizing: border-box;
    @media (max-width: 600px) {
      width: 100%;
      min-height: 100vh;
      padding: 50px 15px 30px;
      border-radius: 0;
    }
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    @media (max-width: 600px) {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__form {
    max-width: 393px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--fullwidth {
      max-width: 100%;
    }
  }
  &__title {
    font-size: 48px;
    margin-bottom: 10px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 32px;
      margin-bottom: 24px;
    }
  }
  &__caption {
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
    max-width: 494px;
    &--mb24 {
      margin-bottom: 24px;
    }
    &--mt22 {
      margin-top: 22px;
    }
    .link {
      color: $link;
      cursor: pointer;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;

      &--disabled {
        color: $tertiary-color;
      }
    }
    @media (max-width: 600px) {
      font-size: 14px;
      margin-bottom: 24px;
    }
  }
  &__text, &__label {
    margin-bottom: 16px;
    max-width: 365px;
    width: 100%;
  }
  &__text {
    font-size: 14px;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
  &__label {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &--error {
      .auth-modal__label-name {
        color: $error;
      }
      .auth-modal__label-input {
        color: $error;
        border: 1px solid $error;
      }
    }
    &-name {
      font-size: 16px;
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
    &-error {
      font-size: 12px;
      color: $error;
      margin-top: 4px;
    }
    &-text {
      font-size: 12px;
      color: $tertiary-color;
      margin-top: 4px;
      &--error {
        color: $error;
      }
    }
    &-icon {
      position: absolute;
      right: 12px;
      top: 40px;
      @media (max-width: 600px) {
        top: 35px;
      }
      &--clickable {
        cursor: pointer;
      }
    }
    &-input {
      border: 1px solid $tertiary-color;
      outline: none;
      max-height: 40px;
      display: flex;
      align-items: center;
      padding: 9px 12px;
      background: $white;
      font-size: 16px;
      border-radius: 8px;
      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
    &-link {
      margin-top: 4px;
      color: $tertiary-color;
      font-size: 12px;
      line-height: 140%;
      align-self: flex-end;
      cursor: pointer;
      user-select: none;
    }
  }
  &__checkbox {
    display: flex;
    gap: 8px;
    max-width: 365px;
    &--error {
      .auth-modal__checkbox-caption {
        color: $error;
      }
      .auth-modal__checkbox-item {
        border: 1px solid $error;
      }
    }
    &-item {
      margin-top: 3px;
      border: 1px solid $tertiary-color;
      min-width: 20px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      &--checked {
        background-image: url("../../assets/icons/icon-checkbox.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &-caption {
      font-size: 14px;
    }
  }
  &__button {
    border: none;
    outline: none;
    display: flex;
    max-width: 365px;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: $primary-color;
    cursor: pointer;
    margin-top: 32px;
    font-size: 24px;
    &:hover, &:active {
      background: $primary-hover-color;
    }
    &--small {
      font-size: 20px;
    }
    &--mt26 {
      margin-top: 26px;
    }
    &--mt0 {
      margin-top: 0;
    }
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  .link-policy {
    color: $link;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
