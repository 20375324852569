<template>
  <div class="policy">
    <span class="policy__title" v-text="title"></span>
    <div class="policy__description" v-html="htmlData"></div>
  </div>
</template>

<script>
import {
  policy,
  agreeOtherPerson,
  agreeSignUp,
  agreeLoyalty
} from '@/constants/policy'

export default {
  name: 'Policy',
  props: {
    mode: {
      type: String,
      default: 'policy'
    }
  },
  data: () => ({
    policy,
    agreeOtherPerson,
    agreeSignUp,
    agreeLoyalty
  }),
  computed: {
    title () {
      if (this.mode === 'other-person') {
        return 'Согласие на обработку персональных данных'
      } else if (this.mode === 'sign-up') {
        return 'Согласие на обработку персональных данных'
      } else if (this.mode === 'loyalty') {
        return 'Программа лояльности кафе Город для гостей'
      } else {
        return 'Политика обработки персональных данных'
      }
    },
    htmlData () {
      if (this.mode === 'other-person') {
        return this.agreeOtherPerson
      } else if (this.mode === 'sign-up') {
        return this.agreeSignUp
      } else if (this.mode === 'loyalty') {
        return this.agreeLoyalty
      } else {
        return this.policy
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/main";
.policy {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 1320px;
  width: 100%;
  overflow-y: auto;
  height: 95%;
  padding: 10px 30px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  &::-webkit-scrollbar { width: 0; }
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 66px;
    color: $footer-color;
    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.02em;
    }
  }
  &__description {
    font-size: 16px;
    line-height: 22px;
    color: $footer-color;
    font-family: 'Manrope', sans-serif !important;
    p {
      margin: 0 0 20px 0;
      font-size: 16px;
      line-height: 22px;
      color: $footer-color;
    }
    b *, strong * {
      font-weight: bold !important;
    }
  }
}
</style>
