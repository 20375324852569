<template>
  <section v-if="news.length" id="news" class="info">
    <div class="info__headline">
      <img src="../../assets/icons/icon-news.svg" alt="news-icon" />
      <div class="info__headline-title">Новости</div>
    </div>
    <carousel
      v-if="isDesktop"
      class="info__slider"
      ref="myCarousel"
      :settings="settings"
      :mouseDrag="false"
      :touchDrag="false"
      @slide-end="setActiveSlide"
    >
      <slide v-for="(item, index) in news" :key="index">
        <div class="info__wrapper container">
          <div class="info__content">
            <div class="info__description" v-if="item.header" v-html="item.header"></div>
            <div class="info__caption" v-html="item.description"></div>
          </div>
          <div class="info__image">
            <img v-if="item.imagePath" :src="item.imagePath" alt="image" />
          </div>
        </div>
      </slide>
      <template v-if="news?.length > 1" #addons>
        <navigation />
        <Pagination v-if="isDesktop" />
      </template>
    </carousel>
    <div class="info__mobile" v-else>
      <div v-for="(item, index) in news" :key="index" class="info__wrapper container">
        <div class="info__content">
          <div class="info__description" v-if="item.header" v-html="item.header"></div>
          <div class="info__caption"> {{ item.description }}</div>
        </div>
        <div class="info__image">
          <img v-if="item.imagePath" :src="item.imagePath" alt="info-image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SectionInfo',
  components: {
    Pagination,
    Carousel,
    Slide,
    Navigation
  },
  data: () => ({
    slide: 0,
    settings: {
      itemsToShow: 1,
      snapAlign: 'start'
    },
    mode: 'hide'
  }),
  computed: {
    ...mapGetters('landing', ['news']),
    isDesktop () {
      return document.body.clientWidth > 840
    }
  },
  methods: {
    ...mapActions('landing', ['getNews']),
    setActiveSlide (data) {
      this.slide = data.currentSlideIndex
      this.$refs.myCarousel.slideTo(data.currentSlideIndex)
    }
  },
  created () {
    this.getNews()
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/vars";
.info {
  @media (max-width: 840px) {
    border-radius: 10px;
    padding: 55px 0 0;
  }
  @media (max-width: 600px) {
    padding: 10px 0 0;
  }
  &__headline {
    text-align: center;
    @media (max-width: 600px) {
      img {
        width: 32px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }
    &-title {
      font-size: $h2-font-size;
      text-transform: uppercase;
      margin-bottom: 20px;
      @media (max-width: 600px) {
        font-size: $h2-font-size;
        margin-bottom: 0;
      }
    }
  }
  .carousel__prev {
    left: -60px !important;
    padding: 10px !important;
  }
  .carousel__next {
    right: -60px !important;
    padding: 10px !important;
  }
  .carousel__slide {
    display: flex;
    justify-content: center;
    width: 100% !important;
    background-color: $primary-color;
    padding: 30px;
    border-radius: 10px;
  }
  .carousel__pagination {
    position: relative;
    margin: -35px 0 20px;
    display: flex;
    gap: 16px;
  }
  .carousel__pagination-button {
    padding: 0;
    &:after {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $primary-color;
    }
  }
  .carousel__pagination-button--active {
    &:after {
      background: $accent-color;
    }
  }
  &__slider {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
  }
  &__wrapper {
    display: flex;
    gap: 60px;
    justify-content: center;
    margin: 0 50px;
    @media (max-width: 840px) {
      flex-direction: column;
      align-items: start;
      background-color: $primary-color;
      max-width: 100%;
      padding: 30px;
      margin: 0;
      gap: 10px;
      border-radius: 10px;
    }
    @media (max-width: 600px) {
      padding: 10px;
    }
  }
  &__mobile {
    @media (max-width: 840px) {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  &__content {
    text-align: justify;
    max-width: 80%;
    @media (max-width: 840px) {
      max-width: 100%;
    }
  }
  &__description {
    font-weight: 500;
    font-size: $primary-font-size;
    text-align: center;
    margin-bottom: 15px;
    @media (max-width: 600px) {
      font-size: $primary-font-size;
      margin-bottom: 10px;
    }
  }
  &__caption {
    max-width: 1600px;
    min-width: 800px;
    font-weight: 300;
    font-size: $primary-font-size;
    @media (max-width: 1440px) {
      min-width: 500px;
    }
    @media (max-width: 1440px) {
      min-width: auto;
    }
    @media (max-width: 540px) {
      font-size: $primary-font-size;
    }
    * {
      font-weight: 300;
      font-size: $primary-font-size;
      @media (max-width: 540px) {
        font-size: $primary-font-size;
      }
    }
  }

  &__image {
    & > img {
      max-height: 100%;
      max-width: 100%;
      border-radius: 10px;
    }
    max-height: 900px;
    max-width: 600px;
    border-radius: 5px;
    img {
      max-width: 600px;
    }
    @media (max-width: 1024px) {
      max-width: 400px;
      img {
        max-width: 400px;
      }
    }
    @media (max-width: 840px) {
      width: auto;
      height: auto;
      img {
        max-width: 100%;
      }
    }
  }
  &__more {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    span {
      font-size: $primary-font-size;
      color: $accent-color;
    }
    img {
      margin-bottom: -4px;
    }
  }
}
</style>
