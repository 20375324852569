<template>
  <div class="selector">
    <div class="selector__title">Выберите ваш город</div>
    <div class="selector__content">
      <SelectorItem
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :activeId="activeId"
        @set-active="setActive"
      />
    </div>
  </div>
</template>

<script>
import SelectorItem from '@/components/selector/SelectorItem.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Selector',
  components: {
    SelectorItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    onSelect: {
      type: Function,
      required: false
    }
  },
  data: () => ({
    activeId: null
  }),
  computed: {
    ...mapGetters('landing', ['selectedCity'])
  },
  methods: {
    ...mapActions('landing', ['setCity']),
    setActive (id) {
      this.activeId = id
      this.setCity(id)
      this.onSelect()
    }
  },
  created () {
    this.activeId = Number(this.selectedCity) || this.items[0].id
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";

.selector {
  background-color: white;
  max-width: 380px;
  padding: 34px 18px;
  &__title {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 10px;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}
</style>
