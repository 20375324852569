import api from '@/boot/api'

const state = {
  selectedCity: localStorage.getItem('city'),
  cities: [],
  addresses: [],
  menu: [],
  news: [],
  tags: [],
  promotions: [],
  resultFranchise: {}
}

const getters = {
  selectedCity: state => state.selectedCity,
  cities: state => state.cities,
  addresses: state => state.addresses,
  menu: state => state.menu,
  news: state => state.news,
  tags: state => state.tags,
  promotions: state => state.promotions,
  resultFranchise: state => state.resultFranchise
}
const mutations = {
  SET_STATE (state, { data, payload }) {
    state[data] = payload
  }
}
const actions = {
  async getCities ({ commit }) {
    const response = await api.get('cities/landing')
    commit('SET_STATE', { data: 'cities', payload: response.data.items })
  },
  async getAddresses ({ commit }) {
    const response = await api.get('addresses/landing')
    commit('SET_STATE', { data: 'addresses', payload: response.data.items })
  },
  async getMenu ({ commit, state }, [page = 0, size, TagIds = []]) {
    const response = await api.get(`nomenclatures/landing?page=${page}&size=${size}&cityId=${Number(localStorage.getItem('city'))}&${TagIds.map(item => `TagIds=${item}`).join('&')}`)
    commit('SET_STATE', { data: 'menu', payload: response.data })
  },
  async getNews ({ commit }) {
    const response = await api.get('news/landing')
    commit('SET_STATE', { data: 'news', payload: response.data.items })
  },
  async getTags ({ commit }) {
    const response = await api.get('tags/menu/landing')
    commit('SET_STATE', { data: 'tags', payload: response.data.items })
  },
  async getPromotions ({ commit }) {
    const response = await api.get('promotions/landing')
    commit('SET_STATE', { data: 'promotions', payload: response.data.items })
  },
  setCity ({ commit }, city) {
    localStorage.setItem('city', city)
    commit('SET_STATE', { data: 'selectedCity', payload: city })
  },
  sendFranchise ({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.post('franchise/respond/actions/submit', data)
        .then(({ data }) => {
          commit('SET_STATE', { data: 'resultFranchise', payload: data.validationResult })
          resolve(data)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
