<template>
  <footer id="contacts" class="footer">
    <div class="footer__wrapper container">
      <div class="footer__content">
        <div class="footer__logo">
          <img src="../assets/icons/logo-footer.svg" alt="">
        </div>
        <div class="nav">
          <div class="nav__title">Навигация</div>
          <ul class="nav__list">
            <li v-for="(item, index) in navigation" :key="index">
              <a :href="item.link">{{ item.title }}</a>
            </li>
            <li>
              <a class="link-policy" @click="$emit('openPolicy')">Политика конфиденциальности</a>
            </li>
          </ul>
        </div>
        <div class="social-networks">
          <div class="social-networks__title">Соц.сети</div>
          <div class="social-networks__list">
            <a href="" target="_blank"><img src="../assets/icons/telegram.svg" alt="telegram"></a>
            <a :href="socialNetworks.vk" target="_blank"><img src="../assets/icons/vk.svg" alt="vk"></a>
          </div>
          <div class="social-networks__payment">
            <img src="../assets/icons/icon-mastercard.svg" alt="mastercard">
            <img src="../assets/icons/icon-visa.svg" alt="visa">
            <img src="../assets/icons/icon-mir.svg" alt="mir">
          </div>
        </div>
        <div v-if="addresses.length" class="address">
          <div class="address__title">Адреса</div>
          <div class="address__list">
            <div
              class="address__item"
              v-for="(address, index) in addresses"
              :key="index"
            >
              <span class="address__item-name">{{ address.name }}</span>
              <span v-if="address.address" class="address__item-schedule">{{ address.address }}</span>
              <span v-if="address.phone" class="address__item-schedule">Телефон:
                <a :href="`tel:${address.phone}`" class="address__item-schedule">
                  {{ address.phone }}
                </a>
              </span>
              <span v-if="address.workSchedule" class="address__item-schedule">{{ address.workSchedule }}</span>
              <span v-if="isDesktop" class="address__item-link" @click="openMap(address)">
                <span>На карте</span>
                <img src="../assets/icons/icon-map.svg" alt="">
              </span>
              <a
                v-else-if="isIOS"
                class="address__item-link"
                :href="` http://maps.google.com/maps?ll=${address.lat},${address.long},`"
              >
                <span>На карте</span>
                <img src="../assets/icons/icon-map.svg" alt="">
              </a>
              <a
                  v-else
                  class="address__item-link"
                  :href="`geo:${address.lat},${address.long}`"
                >
                  <span>На карте</span>
                  <img src="../assets/icons/icon-map.svg" alt="">
                </a>
            </div>
          </div>
          <Modal v-if="isOpenModal" @close="closeMap">
            <Map :coords="addressMap"/>
          </Modal>
        </div>
      </div>
      <div class="footer__prod">
        <span class="copyright">{{ new Date().getFullYear() }} © Кафе Город</span>
      </div>
    </div>
  </footer>
</template>

<script>
import Modal from './Modal'
import Map from '@/components/Map.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Footer',
  components: { Modal, Map },
  data: () => ({
    isOpenModal: false,
    navigation: [
      { title: 'О нас', link: '#about' },
      { title: 'Меню', link: '#menu' },
      { title: 'Акции', link: '#info' },
      { title: 'Новости', link: '#news' },
      { title: 'Самовывоз и оплата', link: '#delivery' },
      { title: 'Франшиза', link: '#franchise' }
    ],
    socialNetworks: {
      vk: 'https://vk.com/kafe.gorod',
      insta: 'https://instagram.com/gorod.cafe?igshid=YWJhMjlhZTc='
    },
    addressMap: []
  }),
  computed: {
    ...mapGetters('landing', ['addresses']),
    isDesktop () {
      return document.body.clientWidth > 600
    },
    isIOS () {
      console.debug(navigator.userAgent)
      return /iPad|iPhone|iPod/.test(navigator.userAgent)
    }
  },
  watch: {
    isOpenModal (value) {
      if (value) document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'auto'
    }
  },
  methods: {
    ...mapActions('landing', ['getAddresses']),
    openMap (address) {
      this.addressMap = [address.lat, address.long]
      this.isOpenModal = true
    },
    closeMap () {
      this.addressMap = []
      this.isOpenModal = false
    }
  },
  created () {
    this.getAddresses()
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";
.footer {
  padding-top: 56px;
  background-color: $footer-color;
  * {
    color: $white;
  }
  @media (max-width: 600px) {
    padding: 30px;
  }
  .link-policy {
    font-size: $secondary-font-size;
    font-weight: 300;
    color: $tertiary-color;
    cursor: pointer;
    &:hover {
      color: $white;
    }
  }
  &__logo {
    @media (max-width: 1440px) {
      img {
        width: 200px;
      }
    }
    @media (max-width: 1280px) {
      img {
        width: 150px;
      }
    }
    @media (max-width: 1024px) {
      grid-column: span 2;
      display: flex;
      justify-content: center;
    }
    @media (max-width: 600px) {
      grid-column: span 1;
      img {
        width: 260px;
      }
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(2, 300px);
      justify-content: center;
      gap: 30px;
      justify-items: center;
    }
    @media (max-width: 600px) {
      grid-template-columns: 315px;
    }
    .nav {
      &__title {
        font-size: $primary-font-size;
        margin-bottom: 10px;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        list-style-type: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        li a {
          font-size: $primary-font-size;
          cursor: pointer;
          color: $tertiary-color;
          font-weight: 300;
          text-decoration: none;
          &:hover {
            color: $white;
          }
        }
        li:last-child {
          border-top: 0.5px solid $tertiary-color;
          padding-top: 5px;
        }
      }
      @media (max-width: 1024px) {
        &__title {
          text-align: center;
        }
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      @media (max-width: 600px) {
        display: none;
      }
    }
    .social-networks {
      img {
        margin-bottom: 10px;
      }
      &__title {
        font-size: $primary-font-size;
        margin-bottom: 16px;
        text-align: center;
      }
      &__list, &__payment {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__list {
        gap: 25px;
        img {
          cursor: pointer;
        }
      }
      &__payment {
        gap: 16px;
        img {
          width: 80%;
        }
      }
      @media (max-width: 600px) {
        order: 2;
      }
    }
    .address {
      &__list {
        display: flex;
        flex-direction: column;
        gap: 25px;
      }

      &__title {
        font-size: $primary-font-size;
        margin-bottom: 10px;
      }
      &__item {
        display: flex;
        flex-direction: column;
        &-name {
          font-size: $primary-font-size;
          font-weight: 300;
        }
        &-schedule {
          font-size: $secondary-font-size;
          font-weight: 300;
          color: $tertiary-color;
        }
        &-link {
          display: flex;
          gap: 10px;
          cursor: pointer;
          text-decoration: none;
          span {
            font-size: $primary-font-size;
            font-weight: 300;
            color: $accent_color;
          }
        }
      }
      @media (max-width: 1024px) {
        text-align: center;
        &__item {
          align-items: center;
        }
      }
      @media (max-width: 600px) {
        order: 1;
        text-align: left;
        justify-self: flex-start;
        &__item {
          align-items: flex-start;
        }
      }
    }
  }
  &__prod {
    padding: 10px 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    span {
      font-weight: 300;
      font-size: $primary-font-size;
    }
    @media (max-width: 600px) {
      padding: 10px 0 30px;
    }
  }
  .copyright {
    color: #434343;
  }

  .made_by {
    &__before {
      color: #434343;
    }

    &__link {
      color: #676767;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        transition: color 0.2s;
        color: #FF75AA;
      }
    }
  }
}
</style>
