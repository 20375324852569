<template>
  <div class="profile">
    <div class="profile__wrapper">
      <div class="profile__header">
        <h1>Личный кабинет</h1>
        <div class="profile__toggle">
          <div
            v-for="item in toggleList"
            :key="item.id"
            class="profile__toggle-item"
            :class="{ 'profile__toggle-item--active' : activeItem === item.id }"
            @click="activeItem = item.id"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <div class="profile__content" :class="{ 'profile__content--center' : activeItem === 2 }">
        <div class="profile__block">
          <template v-if="activeItem === 1">
            <h2 class="desktop">Программа лояльности</h2>
            <div>
              <p>Добро пожаловать в гости в Город!</p>
              <p>Мы рады предложить для Вас программу лояльности для постоянных гостей сети кафе Город, которая сделает Ваш визит к нам не только вкусным, но и ВЫГОДНЫМ!</p>
              <h3>Как это работает?</h3>
              <p>При каждой покупке в любом из наших кафе Вам начисляется кешбэк на блюда собственного производства* в размере 5% от стоимости блюд в форме баллов. 1 балл=1 рубль. Баллы будут начислены сразу после покупки и могут быть использованы для оплаты до 99% следующей покупки. 1 руб по чеку все таки нужно будет пробить.</p>
              <p>Если у Вас уже есть наша карта, пройдите регистрацию на сайте, и Вы сможете пользоваться виртуальной версией карты. Пластиковая карта Вам будет больше не нужна.</p>
              <p>Если у Вас нет нашей карты, предлагаем стать друзьями! Пройдите первичную регистрацию на сайте по номеру телефона, и получайте кешбэк 5% с каждой покупки.*
              </p>
              <p>Присоединяйтесь к нашей программе лояльности и наслаждайтесь нашей домашней кухней с выгодой!
              </p>
              <br>
              <span class="caption">*Кешбэк не начисляется на напитки, десерты, выпечку, шоколад, одноразовую упаковку.</span>
            </div>
            <a @click="openPolicy('loyalty')">Подробные условия программы лояльности</a>
            <br>
            <a @click="openPolicy('policy')">Ссылка на юридические документы</a>
          </template>
          <template v-else>
            <PersonalInfo />
          </template>
        </div>
        <div v-if="isLoading" class="profile__loader">
          <Loader />
        </div>
        <template v-else-if="activeItem === 2">
          <div class="profile__image">
            <img src="../assets/images/image-personal-info.svg" alt="personal-info">
          </div>
        </template>
        <template v-else-if="hasCard">
          <div class="profile__card profile__card-content profile__card-block">
            <div class="profile__card-wrapper">
              <div class="profile__card-qr">
                <qrcode-vue :value="cardInfo.cardId" :size="147" :background="'#F6F6F7'" />
              </div>
              <div class="profile__card-info">
                <div class="title">Карта даёт возможность:</div>
                <ul class="list">
                  <li>Получать кэшбэк {{ cardInfo.bonusPercentages }}% на все готовые блюда кафе «Город»;</li>
                  <li>Пользоваться специальными предложениями и сервисами.</li>
                </ul>
              </div>
            </div>
            <div class="profile__card-wrapper">
              <div class="profile__card-count">
                <img src="../assets/icons/icon-count.svg" alt="icon-count">
                <span>{{ cardInfo.bonusCount }} баллов</span>
              </div>
              <div class="profile__card-number">{{ cardInfo.cardId.slice(-4) }}</div>
            </div>
          </div>
          <h2 class="mobile">{{ toggleList.find(i => i.id === activeItem).text }}</h2>
        </template>
        <div v-else class="profile__card-block">
          <h2 class="mobile">{{ toggleList.find(i => i.id === activeItem).text }}</h2>
          <div class="profile__card"></div>
          <button class="btn btn-primary" @click="mode = 'new'">Выпустить виртуальную карту</button>
          <button class="btn btn-secondary" @click="mode = 'current'">Активировать пластиковую карту</button>
        </div>
      </div>
    </div>
  </div>
  <ActivationCard
    v-if="mode === 'current'"
    @closeModal="mode = 'none'"
  />
  <VirtualCard
    v-if="mode === 'new'"
    @closeModal="mode = 'none'"
    @openPolicy="openPolicy"
  />
  <Modal v-if="mode === 'policy'" @close="closePolicy">
    <Policy :mode="modePolicy" />
  </Modal>
</template>

<script>
import ActivationCard from '@/components/profile/ActivationCard.vue'
import VirtualCard from '@/components/profile/VirtualCard.vue'
import PersonalInfo from '@/components/profile/PersonalInfo.vue'
import { mapGetters, mapActions } from 'vuex'
import Loader from '@/components/Loader.vue'
import Modal from '@/components/Modal.vue'
import Policy from '@/components/popups/Policy.vue'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'ProfilePage',
  components: {
    Policy,
    Modal,
    Loader,
    PersonalInfo,
    VirtualCard,
    ActivationCard,
    QrcodeVue
  },
  data: () => ({
    toggleList: [
      { id: 1, text: 'Программа лояльности' },
      { id: 2, text: 'Личная информация' }
    ],
    activeItem: 1,
    mode: 'none',
    isLoading: true,
    modePolicy: 'policy'
  }),
  computed: {
    ...mapGetters('profile', ['cardInfo']),
    hasCard () {
      return this.cardInfo
    }
  },
  watch: {
    mode (value) {
      if (value !== 'none') document.body.style.overflow = 'hidden'
      else document.body.style.overflow = 'auto'
    }
  },
  methods: {
    ...mapActions('profile', ['getCurrentCard']),
    openPolicy (e) {
      this.modePolicy = e
      this.mode = 'policy'
    },
    closePolicy () {
      if (this.modePolicy !== 'policy' && this.modePolicy !== 'loyalty') {
        this.mode = 'new'
      } else {
        this.mode = 'none'
      }
    }
  },
  created () {
    window.scrollTo({ top: 0 })
    if (!this.$cookies.get('token')) window.location = '/'
    else {
      this.getCurrentCard()
        .catch(error => error)
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/vars";
.desktop-date {
  @media (max-width: 900px) {
    display: none;
  }
}
.mobile-date {
  display: none !important;
  @media (max-width: 900px) {
    display: block !important;
  }
}
input {
  &.mobile-date {
    border: 1px solid $tertiary-color;
    outline: none;
    max-height: 40px;
    display: flex;
    align-items: center;
    padding: 9px 12px;
    background: $white;
    font-size: 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }
}
.desktop {
  @media (max-width: 900px) {
    display: none;
    margin: 0 !important;
  }
}
.mobile {
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
}
.profile {
  display: flex;
  min-height: calc(100vh - 5px);
  width: 100%;
  &__wrapper {
    position: relative;
    padding-top: 100px;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1440px) {
      margin: 0 15px;
    }
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;
      padding-top: 53px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    &--center {
      justify-content: space-between;
      gap: 24px;
    }
    @media (max-width: 900px) {
      flex-direction: column-reverse;
      align-items: center;
    }
    h2 {
      font-size: 32px;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 32px;
      @media (max-width: 600px) {
        font-size: 24px;
        margin: 0 0 24px;
        text-align: center;
      }
    }
  }
  &__header {
    margin-bottom: 32px;
    h1 {
      font-size: 48px;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 32px;
      @media (max-width: 900px) {
        text-align: center;
      }
      @media (max-width: 600px) {
        font-size: 32px;
        margin: 0 0 24px;
      }
    }
    @media (max-width: 600px) {
      margin-bottom: 24px;
    }
  }
  &__loader {
    width: 600px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
      width: 300px;
      height: 200px;
    }
  }
  &__toggle {
    display: inline-flex;
    gap: 4px;
    border-radius: 68px;
    border: 1px solid #E1E1E1;
    padding: 4px;
    box-sizing: border-box;
    &-item {
      cursor: pointer;
      box-sizing: border-box;
      padding: 8px 16px;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      @media (max-width: 600px) {
        font-size: 14px;
        padding: 4px 8px;
      }
      @media (max-width: 375px) {
        font-size: 12px;
        padding: 2px 4px;
      }
      &--active {
        background-color: #FFD79E;
        border-radius: 60px;
      }
    }
  }
  &__image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: calc(100vh - 200px);
    img {
      height: 100%;
    }
    @media (min-width: 1500px) {
      width: 50%;
    }
    @media (max-width: 1200px) {
      width: 50%;
    }
    @media (max-width: 900px) {
      display: none;
    }
  }
  &__block {
    max-width: 688px;
    width: 100%;
    font-size: 18px;
    line-height: 28px;
    margin: 0px 0px 50px;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 22px;
    }
    .caption {
      font-size: 14px;
      @media (max-width: 600px) {
        font-size: 10px;
      }
    }
    > div {
      margin-bottom: 16px;
    }
    a {
      color: $link;
      cursor: pointer;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__card {
    width: 100%;
    height: 332px;
    background: url("../assets/images/card-bg.svg") no-repeat;
    background-size: contain;
    border-radius: 30px;
    padding: 36px 34px 32px 34px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 32px;
    border: 1px solid #B3B4C8;
    @media (max-width: 1200px) {
      height: 285px;
    }
    @media (max-width: 600px) {
      height: 200px;
      padding: 24px;
      margin-bottom: 12px;
    }
    &-content {
      background: #F6F6F7;
    }
    h2 {
      font-size: 32px;
      font-weight: 500;
      margin: 0;
    }
    h3 {
      font-size: 32px;
      font-weight: 600;
      margin: 0;
    }
    &-block {
      width: 569px;
      min-width: 569px;
      @media (max-width: 1200px) {
        width: 450px;
        min-width: 450px;
      }
      @media (max-width: 600px) {
        width: 343px;
        min-width: 343px;
      }
      .btn {
        border: 0;
        outline: 0;
        box-sizing: border-box;
        width: 100%;
        border-radius: 8px;
        padding: 10px;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 16px;
        cursor: pointer;
        @media (max-width: 600px) {
          margin-bottom: 12px;
          font-size: 16px;
        }
      }
      .btn-primary {
        background: #FFEFD9;
      }
      .btn-secondary {
        border: 1px solid #FFA930;
        background-color: $white;
      }
    }
    &-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 32px;
      @media (max-width: 600px) {
        gap: 12px;
      }
    }
    &-qr {
      border-radius: 10px;
      width: 188px;
      height: 188px;
      img {
        height: 100%;
      }
      @media (max-width: 1200px) {
        width: 147px;
        height: 147px;
        img {
          width: 147px;
          height: 147px;
        }
      }
    }
    &-count {
      display: flex;
      align-items: flex-end;
      gap: 12px;
      > span {
        font-size: 28px;
        font-weight: 400;
        color: #B3B4C8;
      }
      @media (max-width: 600px) {
        img {
          width: 14px;
        }
        > span {
          font-size: 14px;
        }
      }
    }
    &-number {
      font-size: 32px;
      font-weight: 400;
      color: #B3B4C8;
      @media (max-width: 600px) {
        font-size: 18px;
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      gap: 12px;
      @media (max-width: 600px) {
        gap: 8px;
      }
      .title {
        font-size: 22px;
        font-weight: 400;
        @media (max-width: 900px) {
          font-size: 18px;
        }
        @media (max-width: 600px) {
          font-size: 12px;
        }
      }
      .list {
        padding-left: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        @media (max-width: 600px) {
          font-size: 10px;
          padding-left: 10px;
        }
      }
    }
  }
}
</style>
