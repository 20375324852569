<template>
  <section v-if="promotions.length" id="info" class="news container">
    <div class="news__headline">
      <img src="../../assets/icons/icon-promotion.svg" alt="promotion-icon" />
      <div class="news__headline-title">Акции</div>
    </div>
    <div ref="news" class="news__slider">
      <carousel
        ref="myCarousel"
        :settings="settings"
        :breakpoints="breakpoints"
        :mouseDrag="displayWidth <= 840"
        :touchDrag="displayWidth <= 840"
        :wrapAround="displayWidth <= 840"
        @slide-end="setActiveSlide"
      >
        <slide v-for="(item, index) in promotions" :key="index">
          <CardPromotion :news="item" :slide="(index + 1) % 3 === 0 ? 3 : (index + 1) % 3" />
        </slide>
        <template v-if="promotions.length > 3 && displayWidth > 840" #addons>
          <navigation />
          <Pagination v-if="isShowPagination" />
        </template>
      </carousel>
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import CardPromotion from '../CardPromotion'
import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'SectionPromotions',
  components: {
    Pagination,
    CardPromotion,
    Carousel,
    Slide,
    Navigation
  },
  props: {
    displayWidth: {
      type: Number
    }
  },
  data: () => ({
    slide: 0,
    settings: {
      itemsToShow: 1,
      snapAlign: 'start'
    },
    breakpoints: {
      841: {
        itemsToShow: 2,
        snapAlign: 'start'
      },
      1367: {
        itemsToShow: 3,
        snapAlign: 'start'
      },
      1920: {
        itemsToShow: 3,
        snapAlign: 'start'
      }
    }
  }),
  computed: {
    ...mapGetters('landing', ['promotions']),
    isShowPagination () {
      if (this.displayWidth >= 1367) {
        return this.promotions.length > 3
      } else if (this.displayWidth >= 841) {
        return this.promotions.length > 2
      } else {
        return this.promotions.length > 1
      }
    }
  },
  methods: {
    ...mapActions('landing', ['getPromotions']),
    setActiveSlide (data) {
      this.slide = data.currentSlideIndex
      this.$refs.myCarousel.slideTo(data.currentSlideIndex)
    }
  },
  created () {
    this.getPromotions()
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/vars";

.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__headline {
    text-align: center;
    @media (max-width: 600px) {
      img {
        width: 32px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
    }
    &-title {
      font-size: $h2-font-size;
      text-transform: uppercase;
      margin-bottom: 20px;
      @media (max-width: 600px) {
        font-size: $h2-font-size;
        margin-bottom: 0;
      }
    }
  }
  &__slider {
    max-width: 1392px;
    width: 100%;
    .carousel__prev {
      left: -50px !important;
      @media (max-width: 1440px) {
        left: -20px !important;
      }
    }
    .carousel__next {
      right: -50px !important;
      @media (max-width: 1440px) {
        right: -20px !important;
      }
    }
    .carousel__slide {
      padding: 0 40px;
      @media (max-width: 840px) {
        padding: 0 10px;
      }
    }
    .carousel__track {
      touch-action: auto !important;
    }
    .carousel__pagination {
      position: relative;
      margin: -35px 0 20px;
      display: flex;
      gap: 16px;
    }
    .carousel__pagination-button {
      padding: 0;
      &:after {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: $primary-color;
      }
    }
    .carousel__pagination-button--active {
      &:after {
        background: $accent-color;
      }
    }
  }
}
</style>
