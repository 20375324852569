<template>
  <section id="cafe" class="cafe container">
    <div class="cafe__content">
      <div class="cafe__headline">
        <div class="cafe__icon">
          <img src="../../assets/icons/icon-cafe.svg" alt="cafe-icon" />
        </div>
        <div class="cafe__headline-title">
          Еда с собой
        </div>
      </div>
      <div class="cafe__description">
        <span v-html="showText"></span>
      </div>
      <div class="cafe__more" @click="mode === 'hide' ? mode = 'show' : mode = 'hide'">
        <!-- <span>{{ mode === 'hide' ? 'Подробнее' : 'Скрыть' }}</span> -->
        <!-- <img src="../../assets/icons/arrow-yellow.svg" alt="arrow-yellow" :style="{ transform : mode === 'show' ? 'rotate(180deg)' : 'rotate(0deg)' }" /> -->
      </div>
    </div>
    <img class="cafe__image" src="../../assets/images/3.jpg" alt="cafe-image" />
  </section>
</template>

<script>
export default {
  name: 'SectionCafe',
  data: () => ({
    text: 'Мы знаем, что Вы цените своё время, поэтому всегда готовы упаковать для Вас еду с собой и сделать процесс покупки максимально удобным.',
    mode: 'normal'
  }),
  computed: {
    showText () {
      // if (partText.length > 50 && this.mode === 'hide') {
      //   partText += '...'
      // }
      return this.text.split(' ').slice(0, this.mode === 'hide' ? 50 : 500).join(' ')
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";
.cafe {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
  @media (max-width: 840px) {
    flex-direction: column;
  }
  &__headline {
    max-width: 662px;
    text-align: center;
    @media (max-width: 840px) {
      max-width: 100%;
    }
    @media (max-width: 600px) {
      img {
        width: 32px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    &-title {
      font-size: $h2-font-size;
      text-transform: uppercase;
      margin-bottom: 20px;
      white-space: nowrap;
      @media (max-width: 600px) {
        font-size: $primary-font-size;
        margin-bottom: 0;
      }
    }
  }
  &__content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 840px) {
      width: 100%;
    }
  }
  &__icon {
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    height: 80px;
    @media (max-width: 840px) {
      max-width: 100%;
    }
  }
  &__description {
    max-width: 800px;
    width: 100%;
    font-weight: 300;
    font-size: $h2-font-size;
    margin-bottom: 20px;
    text-align: justify;
    @media (max-width: 840px) {
      max-width: 100%;
      font-size: $h2-font-size;
    }
    @media (max-width: 375px) {
      font-size: $h2-font-size;
    }
  }
  &__more {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    span {
      font-size: $primary-font-size;
      color: $accent-color;
    }
    img {
      margin-bottom: -4px;
    }
  }
  &__image {
    border-radius: 5px;
    max-height: 800px;
    @media (max-width: 1024px) {
      max-width: 50%;
      img {
        width: 100%;
      }
    }
    @media (max-width: 840px) {
      max-width: 100%;
    }
  }
}
</style>
