<template>
  <div class="separator">
    <div class="separator__content">
      <img class="separator__icon" src="../../assets/icons/icon-cafe-white.svg" alt="icon-cafe" />
      <div class="separator__title">Разнообразное питание</div>
      <div class="separator__description">Разнообразное питание каждый день - залог хорошего настроения!</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionSeparator'
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";
.separator {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1980px;
  width: 100%;
  margin: 0 auto;
  background-image: url("../../assets/images/background-sep.png");
  background-size: cover;
  background-position: center;
  @media (max-width: 600px) {
    height: 100vh;
    padding: 0 10px;
    box-sizing: border-box;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  &__title {
    max-width: 778px;
    width: 100%;
    font-size: $h1-font-size;
    text-align: center;
    color: $white;
    @media (max-width: 600px) {
      font-weight: 300;
      font-size: $h1-font-size;
      letter-spacing: 0.07em;
      -webkit-text-stroke: 1px #FFFFFF;
    }
    @media (max-width: 480px) {
      font-weight: 300;
      font-size: $mobile-h1-font-size;
      letter-spacing: 0.07em;
      -webkit-text-stroke: 1px #FFFFFF;
    }
  }
  &__description {
    max-width: 778px;
    width: 100%;
    font-weight: 300;
    font-size: $h2-font-size;
    text-align: center;
    color: $white;
    word-wrap: break-word;
  }
}
</style>
