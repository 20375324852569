<template>
  <div class="cookie">
    <div class="cookie__title">Cookie</div>
    <div class="cookie__description">
      <div>
        Мы используем файлы cookie, разработанные нашими специалистами и третьими лицами, для анализа событий на нашем веб-сайте, что позволяет нам улучшать взаимодействие с пользователями и обслуживание. Продолжая просмотр страниц нашего сайта, вы принимаете условия его использования. Более подробные сведения смотрите в нашей Политике в отношении файлов Cookie.
      </div>
      <a @click="$emit('open-policy')">Узнать больше</a>
    </div>
    <button @click="$emit('close')">Принимаю все</button>
  </div>
</template>

<style scoped lang="scss">

</style>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Cookie'
})
</script>

<style scoped lang="scss">
@import "src/assets/styles/vars";
.cookie {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: grid;
  grid-template-rows: min-content min-content auto;
  &__title {
    font-size: 48px;
    font-weight: 400;
    margin: 52px 0 60px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 32px;
      margin: 40px 0;
    }
  }
  &__description {
    max-width: 494px;
    width: 100%;
    text-align: justify;
    font-size: 18px;
    margin: 0 auto 140px;
    > div {
      margin-bottom: 20px;
      font-weight: 300;
    }
    a {
      color: $link;
      font-weight: 300;
      cursor: pointer;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    @media (max-width: 600px) {
      margin: 0 0 20px;
    }
  }
  button {
    border: none;
    outline: none;
    display: flex;
    max-width: 365px;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: $primary-color;
    cursor: pointer;
    font-size: 24px;
    margin: 0 auto;
    max-height: 53px;
    &:hover, &:active {
      background: $primary-hover-color;
    }
    @media (max-width: 600px) {
      font-size: 16px;
      max-height: 42px;
      margin-bottom: 18px;
    }
  }
}
</style>
